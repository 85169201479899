import { SelectOption } from './multiselect.types';

export const formatToSelectOptions = (data: any, formatter?: (item: any) => string): SelectOption[] => {
    if (data === null) {
        data = [];
    }
    if (!Array.isArray(data)) {
        data = [data];
    }

    return data.map(
        (d) => ({ value: d, hash: calculateHash(d), label: formatter ? formatter(d) : formatLabel(d) } as SelectOption)
    );
};

export const formatLabel = (data: any) => {
    switch (typeof data) {
        case 'string':
            return data;
        case 'object':
            return JSON.stringify(data);
        default:
            return String(data);
    }
};

export const calculateHash = (data: any) => {
    return hashCode(JSON.stringify(data));
};

export const hashCode = (text: string) => {
    var hash = 0;
    for (var i = 0; i < text.length; i++) {
        var code = text.charCodeAt(i);
        hash = (hash << 5) - hash + code;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { Schedule } from 'src/app/store/reports/pre-mc/model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { ScheduleSaveRequest } from 'src/app/store/reports/pre-mc/actions';

@Component({
    selector: 'app-pre-mc-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: NativeDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    ],
})
export class PreMCScheduleComponent implements OnInit {
    scheduleForm: UntypedFormGroup;
    events = [];

    isLoading$ = this.store.select((state) => state.preMCState.preMCSchedule.isLoading);
    schedule$ = this.store
        .select((state) => state.preMCState.preMCSchedule.entity)
        .pipe(filter((schedule) => !!schedule));

    constructor(private store: Store<ApplicationState>, private formService: FormService) {
        this.scheduleForm = this.formService.createSimpleForm(new Schedule());
    }

    ngOnInit() {
        this.schedule$.subscribe((schedule) => {
            this.scheduleForm.patchValue({
                earlyWorksComplete: schedule.earlyWorksComplete ? new Date(schedule.earlyWorksComplete) : null,
                earlyWalkdownsComplete: schedule.earlyWalkdownsComplete
                    ? new Date(schedule.earlyWalkdownsComplete)
                    : null,
                firstRedZone: schedule.firstRedZone ? new Date(schedule.firstRedZone) : null,
                firstMCWalkdown: schedule.firstMCWalkdown ? new Date(schedule.firstMCWalkdown) : null,
                firstSubsystemMC: schedule.firstSubsystemMC ? new Date(schedule.firstSubsystemMC) : null,
                goocMilestone: schedule.goocMilestone ? new Date(schedule.goocMilestone) : null,
            });
            this.refreshTimeline(this.scheduleForm.value);
        });

        this.scheduleForm.valueChanges.subscribe((schedule) => this.refreshTimeline(schedule));
    }

    save() {
        this.store.dispatch(new ScheduleSaveRequest(this.scheduleForm.value));
    }

    private refreshTimeline(schedule: Schedule) {
        this.events = [
            {
                label: 'Early Works Complete',
                date: schedule.earlyWorksComplete,
                control: this.scheduleForm.controls.earlyWorksComplete,
            },
            {
                label: 'Early Walkdowns Complete',
                date: schedule.earlyWalkdownsComplete,
                control: this.scheduleForm.controls.earlyWalkdownsComplete,
            },
            {
                label: '1st RedZone',
                date: schedule.firstRedZone,
                control: this.scheduleForm.controls.firstRedZone,
            },
            {
                label: '1st MC Walkdown',
                date: schedule.firstMCWalkdown,
                control: this.scheduleForm.controls.firstMCWalkdown,
            },
            {
                label: '1st Subsystem MC',
                date: schedule.firstSubsystemMC,
                control: this.scheduleForm.controls.firstSubsystemMC,
            },
        ];
        this.events = this.events.sort(this.sortNullsFirst);
    }

    private sortNullsFirst(a, b) {
        if (a.date === b.date) {
            return 0;
        } else if (a.date === null) {
            return -1;
        } else if (b.date === null) {
            return 1;
        } else {
            return a.date < b.date ? -1 : 1;
        }
    }
}

import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { EarlyWalkdownsSaveRequest } from 'src/app/store/reports/pre-mc/actions';
import { EarlyWalkdownsDTO } from 'src/app/store/reports/pre-mc/model';

@Component({
    selector: 'app-early-walkdowns',
    templateUrl: './early-walkdowns.component.html',
    styleUrls: ['./early-walkdowns.component.scss'],
})
export class EarlyWalkdownsComponent {
    earlyWalkdownsForm: UntypedFormGroup;

    isLoading$ = this.store.select((state) => state.preMCState.earlyWalkdowns.isLoading);
    earlyWalkdowns$ = this.store.select((state) => state.preMCState.earlyWalkdowns.entity);

    constructor(private store: Store<ApplicationState>, private formService: FormService) {
        this.earlyWalkdownsForm = this.formService.createSimpleForm(new EarlyWalkdownsDTO());
    }

    ngOnInit() {
        this.earlyWalkdowns$
            .pipe(filter((earlyWalkdowns) => !!earlyWalkdowns))
            .subscribe((earlyWalkdowns) => this.earlyWalkdownsForm.patchValue(earlyWalkdowns));
    }

    save() {
        this.store.dispatch(new EarlyWalkdownsSaveRequest(this.earlyWalkdownsForm.value));
    }

    calculatePercentCompleted() {
        const complete = this.earlyWalkdownsForm.controls.complete.value;
        const total = complete + this.earlyWalkdownsForm.controls.scheduled.value;

        const result = total ? ((complete / total) * 100).toFixed(2) : 0;
        return result ? `${result}%` : 0;
    }
}

<div class="pre-mc-skyline-container" *ngIf="(weeks$ | async).length">
    <h3>MC Skyline Plan</h3>
    <app-reports-skyline-chart
        [weeks]="weeks$ | async"
        [subsystemsByWeek]="items$ | async"
        [openAqvdsPerWeek]="openAqvdsPerWeek$ | async"
        [openBitrsPerWeek]="[]"
        [openCitrsPerWeek]="[]"
        [isLoading]="isLoading$ | async"
        pastWeeksCount="0"
        skylinePhase="mc"
        skylineType="plan"
        levelOfDetails="0"
    >
    </app-reports-skyline-chart>
</div>

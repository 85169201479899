import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';

@Component({
    selector: 'app-pre-mc-skyline',
    templateUrl: './skyline.component.html',
    styleUrls: ['./skyline.component.scss'],
})
export class PreMCSkylineComponent {
    isLoading$ = this.store.select((state) => state.preMCState.preMCSkyline.isLoading);
    items$ = this.store.select((state) => state.preMCState.preMCSkyline.items);
    weeks$ = this.store.select((state) => state.preMCState.preMCSkyline.weeks);
    openAqvdsPerWeek$ = this.store.select((state) => state.preMCState.preMCSkyline.openAqvdsPerWeek);

    constructor(private store: Store<ApplicationState>) {}
}

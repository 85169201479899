<div class="result-table margin-right20" *ngIf="earlyWorks$ | async">
    <h3>Early Works</h3>
    <button
        type="button"
        class="sct-button sct-button-light"
        [disabled]="!earlyWorksForm.valid || (isLoading$ | async)"
        (click)="save()"
    >
        save early works
    </button>
    <table [formGroup]="earlyWorksForm">
        <thead>
            <tr>
                <th>Discipline</th>
                <th>Activity</th>
                <th>Planned</th>
                <th>Complete</th>
                <th>% Complete</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowspan="3">Electrical</td>
                <td>Fiber Optic Checks</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="electrical_FiberOpticChecksPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="electrical_FiberOpticChecksCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.electrical_FiberOpticChecksError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>
                    {{
                        calculatePercentCompleted(
                            'electrical_FiberOpticChecksPlanned',
                            'electrical_FiberOpticChecksCompleted'
                        )
                    }}
                </td>
            </tr>
            <tr>
                <td>Heat Trace Cold Check</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="electrical_HeatTraceColdCheckPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            min="0"
                            formControlName="electrical_HeatTraceColdCheckCompleted"
                        />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.electrical_HeatTraceColdCheckError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>
                    {{
                        calculatePercentCompleted(
                            'electrical_HeatTraceColdCheckPlanned',
                            'electrical_HeatTraceColdCheckCompleted'
                        )
                    }}
                </td>
            </tr>
            <tr>
                <td>Heat Trace Hot Check</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="electrical_HeatTraceHotCheckPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="electrical_HeatTraceHotCheckCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.electrical_HeatTraceHotCheckError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>
                    {{
                        calculatePercentCompleted(
                            'electrical_HeatTraceHotCheckPlanned',
                            'electrical_HeatTraceHotCheckCompleted'
                        )
                    }}
                </td>
            </tr>
            <tr>
                <td rowspan="2">I&amp;C</td>
                <td>Cold Loop Checks</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="iC_ColdLoopChecksPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="iC_ColdLoopChecksCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.iC_ColdLoopChecksError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>{{ calculatePercentCompleted('iC_ColdLoopChecksPlanned', 'iC_ColdLoopChecksCompleted') }}</td>
            </tr>
            <tr>
                <td>Louver Actuation Testing</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="iC_LouverActuationTestingPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="iC_LouverActuationTestingCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.iC_LouverActuationTestingError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>
                    {{
                        calculatePercentCompleted(
                            'iC_LouverActuationTestingPlanned',
                            'iC_LouverActuationTestingCompleted'
                        )
                    }}
                </td>
            </tr>
            <tr>
                <td rowspan="2">SMP</td>
                <td>Pump Alignment</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="smP_PumpAlignmentPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="smP_PumpAlignmentCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.smP_PumpAlignmentError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>{{ calculatePercentCompleted('smP_PumpAlignmentPlanned', 'smP_PumpAlignmentCompleted') }}</td>
            </tr>
            <tr>
                <td>Pulley Alignment</td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="smP_PulleyAlignmentPlanned" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="smP_PulleyAlignmentCompleted" />
                    </mat-form-field>
                    <mat-error *ngIf="earlyWorksForm.errors?.smP_PulleyAlignmentError"
                        >Completed value should be less or equal to Planned value.</mat-error
                    >
                </td>
                <td>{{ calculatePercentCompleted('smP_PulleyAlignmentPlanned', 'smP_PulleyAlignmentCompleted') }}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="isLoading$ | async" class="upload-log-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>

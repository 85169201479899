import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export function plannedGteCompletedValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
        const electrical_FiberOpticChecksCompletedValue = formGroup.get('electrical_FiberOpticChecksCompleted').value;
        const electrical_FiberOpticChecksPlannedValue = formGroup.get('electrical_FiberOpticChecksPlanned').value;

        const electrical_HeatTraceColdCheckCompletedValue = formGroup.get('electrical_HeatTraceColdCheckCompleted')
            .value;
        const electrical_HeatTraceColdCheckPlannedValue = formGroup.get('electrical_HeatTraceColdCheckPlanned').value;

        const electrical_HeatTraceHotCheckCompletedValue = formGroup.get('electrical_HeatTraceHotCheckCompleted').value;
        const electrical_HeatTraceHotCheckPlannedValue = formGroup.get('electrical_HeatTraceHotCheckPlanned').value;

        const iC_ColdLoopChecksCompletedValue = formGroup.get('iC_ColdLoopChecksCompleted').value;
        const iC_ColdLoopChecksPlannedValue = formGroup.get('iC_ColdLoopChecksPlanned').value;

        const iC_LouverActuationTestingCompletedValue = formGroup.get('iC_LouverActuationTestingCompleted').value;
        const iC_LouverActuationTestingPlannedValue = formGroup.get('iC_LouverActuationTestingPlanned').value;

        const smP_PumpAlignmentCompletedValue = formGroup.get('smP_PumpAlignmentCompleted').value;
        const smP_PumpAlignmentPlannedValue = formGroup.get('smP_PumpAlignmentPlanned').value;

        const smP_PulleyAlignmentCompletedValue = formGroup.get('smP_PulleyAlignmentCompleted').value;
        const smP_PulleyAlignmentPlannedValue = formGroup.get('smP_PulleyAlignmentPlanned').value;

        const result = {
            electrical_FiberOpticChecksError:
                electrical_FiberOpticChecksPlannedValue < electrical_FiberOpticChecksCompletedValue,
            electrical_HeatTraceColdCheckError:
                electrical_HeatTraceColdCheckPlannedValue < electrical_HeatTraceColdCheckCompletedValue,
            electrical_HeatTraceHotCheckError:
                electrical_HeatTraceHotCheckPlannedValue < electrical_HeatTraceHotCheckCompletedValue,
            iC_ColdLoopChecksError: iC_ColdLoopChecksPlannedValue < iC_ColdLoopChecksCompletedValue,
            iC_LouverActuationTestingError:
                iC_LouverActuationTestingPlannedValue < iC_LouverActuationTestingCompletedValue,
            smP_PumpAlignmentError: smP_PumpAlignmentPlannedValue < smP_PumpAlignmentCompletedValue,
            smP_PulleyAlignmentError: smP_PulleyAlignmentPlannedValue < smP_PulleyAlignmentCompletedValue,
        };

        _.keys(result)
            .filter((key) => !result[key])
            .forEach((key) => delete result[key]);
        return _.isEmpty(result) ? null : result;
    };
}

import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { FormService } from 'src/app/services/shared/form.service';
import { Gooc } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import {
    AQVDRequestStatusRequest,
    ChangeValidationRequest,
    EarlyWalkdownsRequest,
    EarlyWorksRequest,
    PLIValidationRequest,
    PreMCSkylineRequest,
    ScheduleRequest,
    SOCPlanRequest,
} from 'src/app/store/reports/pre-mc/actions';

@Component({
    selector: 'app-gooc-pre-mc',
    templateUrl: './pre-mc.component.html',
    styleUrls: ['./pre-mc.component.scss'],
})
export class GoocPreMCComponent implements OnInit {
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    filterForm: UntypedFormGroup;

    gooc$ = this.store.select((state) => state.preMCState.gooc).pipe(filter((gooc) => !!gooc));
    isLoading$ = this.store.select(
        (state) =>
            (state.preMCState.certificationStatus.aqvdRequestStatus.isLoading &&
                !state.preMCState.certificationStatus.aqvdRequestStatus.items.length) ||
            (state.preMCState.certificationStatus.changeValidation.isLoading &&
                !state.preMCState.certificationStatus.changeValidation.entity) ||
            (state.preMCState.certificationStatus.pliValidation.isLoading &&
                !state.preMCState.certificationStatus.pliValidation.entity) ||
            (state.preMCState.earlyWalkdowns.isLoading && !state.preMCState.earlyWalkdowns.entity) ||
            (state.preMCState.earlyWorks.isLoading && !state.preMCState.earlyWorks.entity) ||
            (state.preMCState.preMCSchedule.isLoading && !state.preMCState.preMCSchedule.entity) ||
            (state.preMCState.preMCSkyline.isLoading && !state.preMCState.preMCSkyline.items.length) ||
            (state.preMCState.socPlan.isLoading && !state.preMCState.socPlan.entity)
    );

    constructor(
        private lookupService: LookupService,
        private formService: FormService,
        private store: Store<ApplicationState>
    ) {
        this.filterForm = this.formService.createSimpleForm({ goocs: [] });
    }

    ngOnInit() {
        this.gooc$.subscribe((gooc) => this.filterForm.controls.goocs.patchValue([gooc]));
    }

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    resetFilters() {
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
    }

    search() {
        this.store.dispatch(new EarlyWorksRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new EarlyWalkdownsRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new PLIValidationRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new ChangeValidationRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new AQVDRequestStatusRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new SOCPlanRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new PreMCSkylineRequest(this.filterForm.controls.goocs.value[0]));
        this.store.dispatch(new ScheduleRequest(this.filterForm.controls.goocs.value[0]));
    }
}

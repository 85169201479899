<main class="main">
    <div class="title-container">
        <div class="title">
            Upload native DCN file containing impacted tags
        </div>
    </div>
    <form [formGroup]="filterForm">
        <mat-accordion class="filter-container">
            <mat-expansion-panel #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.number.value" removable>
                                Number: {{ filterForm.controls.number.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('number')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.title.value" removable>
                                Title: {{ filterForm.controls.title.value }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('title')">cancel </mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container mixedapp-container-last">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Number</mat-label>
                        <input formControlName="number" matInput />
                    </mat-form-field>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Title</mat-label>
                        <input formControlName="title" matInput />
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <button type="button" (click)="resetFilters()" class="sct-button sct-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="sct-button sct-button-light">search</button>
        </div>
    </form>
    <div class="results-container">
        <table
            mat-table
            matSort
            [dataSource]="data"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="number">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.number }}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>
            <ng-container matColumnDef="impactedTags">
                <th mat-header-cell disableClear *matHeaderCellDef>Impacted Tag</th>
                <td mat-cell *matCellDef="let element">{{ parseWithCommas(element.impactedTags) }}</td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="openPopup(element)" title="edit">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="importFile.click($event)" title="upload">
                        <mat-icon>cloud_upload</mat-icon>
                        <input
                            #importFile
                            type="file"
                            (click)="importFile.value = null"
                            (change)="fileChangeEvent($event.target.files, element.id)"
                            style="display: none;"
                        />
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="paginator page-counter">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 15, 20]"
                (page)="onPaginatorChange($event)"
                showFirstLastButtons
            >
            </mat-paginator>
            <input type="number" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</main>

<main class="main">
    <form [formGroup]="filterForm">
        <div class="header-container">
            <div class="header-title">
                <span *ngIf="lockFilters; else changeMgmtTitle">{{ headerText }}</span>
                <ng-template #changeMgmtTitle>Change management</ng-template>
            </div>
            <div class="header-buttons">
                <button type="button" (click)="exportToExcel()" class="sct-button sct-button-light margin-right20" *ngIf="blueScreen">
                    export to excel
                </button>
            </div>
        </div>
        <mat-accordion class="filter-container">
            <mat-expansion-panel [ngClass]="{'hideme':!showFilterPanel}" [disabled]="lockFilters" #filterExpansionPanel="matExpansionPanel">
                <mat-expansion-panel-header collapsedHeight="auto">
                    <div *ngIf="!filterExpansionPanel.expanded">
                        <mat-chip-list aria-label="Active filters">
                            <mat-basic-chip *ngIf="filterForm.controls.projectTeamNames.value.length" removable>
                                Project Team: {{ filterForm.controls.projectTeamNames.value.join(', ') }}
                                <mat-icon
                                    matChipRemove
                                    (click)="clearFilterProperty('projectTeamNames')"
                                    *ngIf="!lockFilters"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.contractors.value.length" removable>
                                Contractor: {{ displayMultipleSelected(filterForm.controls.contractors.value, 'name') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('contractors')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.changeTypes.value.length" removable>
                                Change Type: {{ filterForm.controls.changeTypes.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('changeTypes')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.turnoverGroups.value.length" removable>
                                Turnover Group:
                                {{ displayMultipleSelected(filterForm.controls.turnoverGroups.value, 'id') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('turnoverGroups')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.subsystems.value.length" removable>
                                Subsystem: {{ displayMultipleSelected(filterForm.controls.subsystems.value, 'id') }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('subsystems')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcMilestones.value.length" removable>
                                MC Milestone:
                                {{ displayMultipleSelected(filterForm.controls.mcMilestones.value, 'name') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('mcMilestones')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfos.value.length" removable>
                                RFO: {{ displayMultipleSelected(filterForm.controls.rfos.value, 'name') }}
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.disciplines.value.length" removable>
                                Discipline: {{ filterForm.controls.disciplines.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('disciplines')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.designStatuses.value.length" removable>
                                Design Status: {{ filterForm.controls.designStatuses.value.join(', ') }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('designStatuses')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.mcEngineer?.value.length" removable>
                                MC Engineer: {{ displaySelectedMCEngineer(filterForm.controls.mcEngineer.value) }}
                                <mat-icon *ngIf="!lockFilters" matChipRemove (click)="clearFilterProperty('mcEngineer')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.systemOwner?.value.length" removable>
                                System Owner: {{ displaySelectedSystemOwner(filterForm.controls.systemOwner.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('systemOwner')"
                                    >cancel
                                </mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.stagedStartUpPriority?.value.length" removable>
                                Priorities:
                                {{ displaySelectedPriorities(filterForm.controls.stagedStartUpPriority.value) }}
                                <mat-icon
                                    *ngIf="!lockFilters"
                                    matChipRemove
                                    (click)="clearFilterProperty('stagedStartUpPriority')"
                                    >cancel</mat-icon
                                >
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.areaBreakdown.value.length" removable>
                                Area Breakdown:
                                {{ displayMultipleSelectedById(filterForm.controls.areaBreakdown.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('areaBreakdown')">cancel</mat-icon>
                            </mat-basic-chip>
                            <mat-basic-chip *ngIf="filterForm.controls.rfsuContractors.value.length" removable>
                                RFSU Contractor:
                                {{ displayMultipleSelectedById(filterForm.controls.rfsuContractors.value) }}
                                <mat-icon matChipRemove (click)="clearFilterProperty('rfsuContractors')">cancel</mat-icon>
                            </mat-basic-chip>
                        </mat-chip-list>
                    </div>
                </mat-expansion-panel-header>
                <div class="mixedapp-container">
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Project Team</mat-label>
                        <mat-select
                            formControlName="projectTeamNames"
                            multiple
                            (openedChange)="onProjectTeamsClosed($event)"
                        >
                            <mat-option *ngFor="let pt of projectTeamNames" [value]="pt">{{ pt }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setContractorInput"
                            formControlName="contractors"
                            [searchFunc]="getContractorsMulti"
                            [isAsync]="true"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            (autocompleteClosed)="onContractorsClosed()"
                            (itemRemoved)="onContractorsClosed()"
                            [filterForm]="filterForm"
                            [formSetter]="'contractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'Contractor'"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Change Type</mat-label>
                        <mat-select formControlName="changeTypes" multiple>
                            <mat-option *ngFor="let ct of changeTypes" [value]="ct">{{ ct }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="container300">
                        <mat-label>Design Status</mat-label>
                        <mat-select formControlName="designStatuses" multiple>
                            <mat-option *ngFor="let is of designStatuses" [value]="is">{{ is }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="multiapp-container">
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFOInput"
                            formControlName="rfos"
                            [searchFunc]="getRFOs"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'rfos'"
                            [propertyToShow]="'name'"
                            [placeholder]="'RFO'"
                            [secondPropertyToShow]="'rfoName'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['name', 'rfoName']"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setRFSUContractorsInput"
                            formControlName="rfsuContractors"
                            [searchFunc]="getRFSUContractors"
                            [isAsync]="true"
                            [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                            [filterForm]="filterForm"
                            [formSetter]="'rfsuContractors'"
                            [propertyToShow]="'contractNo'"
                            [placeholder]="'RFSU Contractor'"
                            [panelWidth]="480"
                            [secondPropertyToShow]="'contract'"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <div class="container300-mh margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            [setInput]="setAreaBreakdownInput"
                            formControlName="areaBreakdown"
                            [searchFunc]="getAreaBreakdown"
                            [isAsync]="true"
                            [filterForm]="filterForm"
                            [formSetter]="'areaBreakdown'"
                            [placeholder]="'Area Breakdown'"
                        >
                        </app-multiple-items-selector>
                    </div>
                </div>
                <div class="mixedapp-container">
                    <div class="margin-right20">
                        <app-multiple-items-selector
                            class="container300"
                            formControlName="subsystems"
                            [searchFunc]="getSubsystems"
                            [setInput]="setSubsystemInput"
                            [isAsync]="true"
                            placeholder="Subsystem"
                            [displayedColumns]="subsystemAutocompleteDisplayedColumns"
                            [ngStyle]="{ display: isIE == true ? 'inline' : null }"
                            [panelWidth]="480"
                        >
                        </app-multiple-items-selector>
                    </div>
                    <mat-form-field class="container300 margin-right20">
                        <mat-label>Discipline</mat-label>
                        <mat-select formControlName="disciplines" multiple>
                            <mat-option *ngFor="let disc of disciplines" [value]="disc">{{ disc }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    
                    <app-multiple-items-selector
                            class="container300 margin-right20"
                            [setInput]="setStagedStartUpPriorityInput"
                            formControlName="stagedStartUpPriority"
                            [isAsync]="true"
                            [searchFunc]="getStagedStartUpPriority"
                            [filterForm]="filterForm"
                            [formSetter]="'stagedStartUpPriority'"
                            [propertyToShow]="'priorityName'"
                            [placeholder]="'Staged Start-up Priority'"
                            [secondPropertyToShow]="'priority'"
                            [isTableAutoComplete]="true"
                            [displayedColumns]="['priority', 'priorityName']"
                    >
                    </app-multiple-items-selector>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="buttons-container flex">
            <span
                matTooltip="To generate ss report you need to have one and only one Subsystem selected."
                matTooltipClass="scttooltip"
                [matTooltipDisabled]="filterForm.controls.subsystems.value.length == 1"
            >
                <button
                    type="button"
                    *ngIf="!lockFilters && !isReadOnly"
                    class="sct-button sct-button-light margin-right20"
                    (click)="navigateToFieldChangeSummary()"
                    [disabled]="filterForm.controls.subsystems.value.length != 1"
                >
                    generate ss report
                </button>
            </span>
            <button type="button" class="sct-button sct-button-light margin-right20" (click)="exportToExcel()" *ngIf="!blueScreen">
                export to excel
            </button>
            <button
                type="button"
                *ngIf="!lockFilters"
                (click)="resetFilters()"
                class="sct-button sct-button-light margin-right20"
            >
                reset filters
            </button>
            <button type="button" *ngIf="!lockFilters" (click)="search()" class="sct-button sct-button-light">
                search
            </button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                <div class="results-header-actions">
                    <button style="color:#00adee !important; outline: none;"
                        mat-button
                        [matMenuTriggerFor]="columnsCustomizer"
                        class="results-columns-customizer"
                    >
                        <mat-icon svgIcon="edit"></mat-icon>
                        customize columns
                    </button>
                    <mat-menu #columnsCustomizer class="columns-customizer">
                        <button mat-button (click)="resetColumns()">Reset columns &times;</button>
                        <div class="columns-customizer-list">
                            <mat-checkbox 
                                [checked]="showHideColumns.walkdownActual" 
                                (change)="reverseValue('walkdownActual')" 
                                (click)="$event.stopPropagation()"
                                >Walkdown Actual</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.walkdownForecast" 
                                (change)="reverseValue('walkdownForecast')" 
                                (click)="$event.stopPropagation()"
                                >Walkdown Forecast Date</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.createdDate" 
                                (change)="reverseValue('createdDate')" 
                                (click)="$event.stopPropagation()"
                                >Date of First Import</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.mcActual" 
                                (change)="reverseValue('mcActual')" 
                                (click)="$event.stopPropagation()"
                                >Mc Actual</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.type" 
                                (change)="reverseValue('type')" 
                                (click)="$event.stopPropagation()"
                                >Type</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.discp" 
                                (change)="reverseValue('discp')" 
                                (click)="$event.stopPropagation()"
                                >Discipline</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.areaBreakDown" 
                                (change)="reverseValue('areaBreakDown')" 
                                (click)="$event.stopPropagation()"
                                >Area Breakdown</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfo" 
                                (change)="reverseValue('rfo')"
                                (click)="$event.stopPropagation()"
                                >RFO</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfoStatus" 
                                (change)="reverseValue('rfoStatus')"
                                (click)="$event.stopPropagation()"
                                >RFO Status</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.title" 
                                (change)="reverseValue('title')"
                                (click)="$event.stopPropagation()"
                                >Doc Title</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.subsystem" 
                                (change)="reverseValue('subsystem')"
                                (click)="$event.stopPropagation()"
                                >Subsystem</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.signedSubsystem" 
                                (change)="reverseValue('signedSubsystem')"
                                (click)="$event.stopPropagation()"
                                >Signed Subsystem</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.date" 
                                (change)="reverseValue('date')"
                                (click)="$event.stopPropagation()"
                                >Doc Issue Date</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.designStatus" 
                                (change)="reverseValue('designStatus')"
                                (click)="$event.stopPropagation()"
                                >Design Status</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.stepStatus" 
                                (change)="reverseValue('stepStatus')"
                                (click)="$event.stopPropagation()"
                                >IPIMS Step</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.implementationStatus" 
                                (change)="reverseValue('implementationStatus')"
                                (click)="$event.stopPropagation()"
                                >Implementation Status</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.responsiblePerson" 
                                (change)="reverseValue('responsiblePerson')"
                                (click)="$event.stopPropagation()"
                                >Responsible Person</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.contract" 
                                (change)="reverseValue('contract')"
                                (click)="$event.stopPropagation()"
                                >Contractor</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfsuContractor" 
                                (change)="reverseValue('rfsuContractor')"
                                (click)="$event.stopPropagation()"
                                >RFSU Contractor</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.rfsuContractsPending" 
                                (change)="reverseValue('rfsuContractsPending')"
                                (click)="$event.stopPropagation()"
                                >RFSU Contracts Pending</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.comment" 
                                (change)="reverseValue('comment')"
                                (click)="$event.stopPropagation()"
                                >Comment</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.fieldImplementationContractor" 
                                (change)="reverseValue('fieldImplementationContractor')"
                                (click)="$event.stopPropagation()"
                                >Field Implementation Contractor</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.exceptionNumber" 
                                (change)="reverseValue('exceptionNumber')"
                                (click)="$event.stopPropagation()"
                                >Exceptions</mat-checkbox
                            >
                            <mat-checkbox 
                                [checked]="showHideColumns.priority" 
                                (change)="reverseValue('priority')"
                                (click)="$event.stopPropagation()"
                                >Priority</mat-checkbox
                            >
                            <mat-checkbox 
                            [checked]="showHideColumns.rfiOriginator" 
                            (change)="reverseValue('rfiOriginator')"
                            (click)="$event.stopPropagation()"
                            >RFI Originator</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.revision"
                                (change)="reverseValue('revision')"
                                (click)="$event.stopPropagation()"
                                >Revision</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.rfsuForecast"
                                (change)="reverseValue('rfsuForecast')"
                                (click)="$event.stopPropagation()"
                                >RFSU Forecast</mat-checkbox
                            >
                            <mat-checkbox
                                [checked]="showHideColumns.rfoForecast"
                                (change)="reverseValue('rfoForecast')"
                                (click)="$event.stopPropagation()"
                                >RFO Forecast</mat-checkbox
                            >                            
                            <mat-checkbox
                                [checked]="showHideColumns.validationStatus"
                                (change)="reverseValue('validationStatus')"
                                (click)="$event.stopPropagation()"
                                >Validation Status</mat-checkbox
                            >                            
                            <mat-checkbox
                                [checked]="showHideColumns.expectedTimeOfCompletion"
                                (change)="reverseValue('expectedTimeOfCompletion')"
                                (click)="$event.stopPropagation()"
                                >ETC</mat-checkbox
                            >                            
                           
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="scroll">
                <div class="table-container">
                    <app-mat-table-sticky-header [scrollKey]="'changemanagement'">
                        <table
                            mat-table
                            matSort
                            [dataSource]="data"
                            [matSortActive]="sortBy"
                            matSortDisableClear
                            [matSortDirection]="direction"
                        >
                            <ng-container matColumnDef="type">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.type
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'type',
                                            getTypes,
                                            'columnChangeType',
                                            'Type',
                                            columnChangeType,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnChangeType)
                                            }"
                                            >Type</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'type'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.type }">
                                    {{ element.type }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="id" sticky>
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        idasfirstcol: !showHideColumns.type
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter('id', getIds, 'columnId', 'ID', columnId)
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnId)
                                            }"
                                            >ID</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'id'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ idasfirstcol: !showHideColumns.type }"
                                >
                                    <a (click)="openDetailsPage(element)">{{ element.number }}</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="discipline">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.discp
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'discipline',
                                            getDisciplines,
                                            'columnDiscipline',
                                            'Discipline',
                                            columnDiscipline,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                                            }"
                                            >Discipline</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.discp }"
                                >
                                    {{ element.discipline }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="title">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.title
                                    }"
                                >
                                    <div class="sort-container">
                                        <span
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'title',
                                                    getTitles,
                                                    'columnTitle',
                                                    'Title',
                                                    columnTitle
                                                )
                                            "
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnTitle)
                                            }"
                                            >Doc Title</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'title'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.title }"
                                    [ngStyle]="{ 'max-width': showHideColumns.comment ? '452px' : '' }"
                                >
                                    {{ element.title }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfo">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfo
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'rfo',
                                                    getRFO,
                                                    'columnRFO',
                                                    'RFO',
                                                    columnRFO,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFO)
                                            }"
                                            >RFO</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfo'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfo }"
                                >
                                {{ element.rfo | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoStatus">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoStatus
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'rfoStatus',
                                                    getRFOStatus,
                                                    'columnRFOStatus',
                                                    'RFO Status',
                                                    columnRFOStatus,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFOStatus)
                                            }"
                                            >RFO Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfoStatus }"
                                >
                                {{ element.rfoStatus | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="subsystem">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.subsystem,
                                        'header-underline': showUnderlineForCheckListColumn(columnSubsystem)
                                    }"
                                >
                                    <div class="sort-container center">
                                        <span
                                            class="hover-pointer"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'subsystem',
                                                    getSubsystemsForColumn,
                                                    'columnSubsystem',
                                                    'Subsystem',
                                                    columnSubsystem,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            >Subsystem</span
                                        >
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.subsystem }"
                                >
                                    {{ element.subsystem | breakLine: ',' }}
                                    {{ element.subsystemImpactedNA ? 'N/A' : '' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="signedSubsystem">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.signedSubsystem,
                                        'header-underline': showUnderlineForCheckListColumn(columnSignedSubsystem)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'signedSubsystem',
                                                getSignedSubsystemsForColumn,
                                                'columnSignedSubsystem',
                                                'Signed Subsystem',
                                                columnSignedSubsystem,
                                                false,
                                                true,
                                                true
                                            )
                                        "
                                        >Signed Off Subsystems</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.signedSubsystem }"
                                >
                                    {{ beatifySighnedSS(element) | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="linkedIWPs">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.linkedIWPs,
                                        'header-underline': showUnderlineForCheckListColumn(columnLinkedIWPs)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'linkedIWPs',
                                                getLinkedIWPsForColumn,
                                                'columnLinkedIWPs',
                                                'Linked IWPs',
                                                columnLinkedIWPs,
                                                false,
                                                true
                                            )
                                        "
                                        >Linked IWPs</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.linkedIWPs }"
                                >
                                    {{ element.linkedIWPs }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="walkdownForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.walkdownForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('walkdownForecast', 'columnWalkdownForecast', 'Walkdown Forecast Date', columnWalkdownForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnWalkdownForecast)
                                            }"
                                            >Walkdown Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'walkdownForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.walkdownForecast }">
                                    {{ element.walkdownForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="createdDate">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.createdDate
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('createdDate', 'columnCreatedDate', 'Created Date', columnCreatedDate)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnCreatedDate)
                                            }"
                                            >Date of First Import</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'createdDate'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.createdDate }">
                                    {{ element.createdDate | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="revision">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.revision
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'revision',
                                            getRevisions,
                                            'columnRevision',
                                            'Revision',
                                            columnRevision,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRevision)
                                            }"
                                            >Revision</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'Revision'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.revision }"
                                    *matCellDef="let element"
                                >
                                    {{ element.revision }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="areaBreakDown">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.areaBreakDown
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'areaBreakDown',
                                            getAreaBreakDown,
                                            'columnAreaBreakDown',
                                            'AreaBreakDown',
                                            columnAreaBreakDown,
                                            true,
                                            true
                                        )
                                    "
                                >
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnAreaBreakDown)
                                            }"
                                            >Area Breakdown</span
                                        >
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.areaBreakDown }"
                                    *matCellDef="let element"
                                >
                                    {{ element.areaBreakDown }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="exceptionNumber">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.exceptionNumber
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'exceptionNumber',
                                            getExceptionNumber,
                                            'columnExceptionNumber',
                                            'Exception Number',
                                            columnExceptionNumber,
                                            true,
                                            true
                                        )
                                    "
                                >
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnExceptionNumber)
                                            }"
                                            >Exception Number</span
                                        >
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.exceptionNumber }"
                                    *matCellDef="let element"
                                >
                                    {{ element.exceptionNumber | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="systemOwner">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.systemOwner
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'systemOwner',
                                            getSystemOwners,
                                            'columnSystemOwner',
                                            'SystemOwner',
                                            columnSystemOwner,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnSystemOwner)
                                            }"
                                            >System Owner</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'systemOwner'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.systemOwner }"
                                    *matCellDef="let element"
                                >
                                    {{ element.systemOwner }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="rfsuForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfsuForecast', 'columnRFSUForecast', 'RFSU Forecast Date', columnRFSUForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFSUForecast)
                                            }"
                                            >RFSU Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfsuForecast }">
                                    {{ element.rfsuForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfoForecast">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfoForecast
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfoForecast', 'columnRFOForecast', 'RFO Forecast Date', columnRFOForecast)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFOForecast)
                                            }"
                                            >RFO Forecast Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfoForecast'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfoForecast }">
                                    {{ element.rfoForecast | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>                   
                            <ng-container matColumnDef="rfsuPlan">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuPlan
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('rfsuPlan', 'columnRFSUPlan', 'RFSU Plan Date', columnRFSUPlan)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnRFSUPlan)
                                            }"
                                            >RFSU Plan Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'rfsuPlan'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.rfsuPlan }">
                                    {{ element.rfsuPlan | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.date
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('date', 'columnDate', 'Date', columnDate)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnDate)
                                            }"
                                            >Doc Issue Date</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'date'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.date }">
                                    {{ element.date | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mcActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.mcActual
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('mcActual', 'columnMcActual', 'Mc Actual', columnMcActual)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnMcActual)
                                            }"
                                            >Mc Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'mcActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.mcActual }">
                                    {{ element.mcActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="walkdownActual">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.walkdownActual
                                    }"
                                    class="hover-pointer"
                                    (click)="openHeaderDateFilter('walkdownActual', 'columnWalkdownActual', 'Walkdown Actual', columnWalkdownActual)"
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnWalkdownActual)
                                            }"
                                            >Walkdown Actual</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'walkdownActual'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{ hidecells: !showHideColumns.walkdownActual }">
                                    {{ element.walkdownActual | date: 'd/MMM/yy' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="linkedCOFs">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.linkedCOFs,
                                        'header-underline': showUnderlineForCheckListColumn(columnLinkedCOFs)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'linkedCOFs',
                                                getLinkedCOFsForColumn,
                                                'columnLinkedCOFs',
                                                'Linked COFs',
                                                columnLinkedCOFs,
                                                false,
                                                true
                                            )
                                        "
                                        >Linked COFs</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.linkedCOFs }"
                                >
                                    {{ element.linkedCOFs }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuContractor">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuContractor,
                                        'header-underline': showUnderlineForCheckListColumn(columnRFSUContractor)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'rfsuContractor',
                                                getRFSUContractorForColumn,
                                                'columnRFSUContractor',
                                                'RFSU Contractor',
                                                columnRFSUContractor,
                                                false,
                                                true
                                            )
                                        "
                                        >RFSU Contractor</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuContractor }"
                                >
                                    {{ element.rfsuContractor | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfsuContractsPending">
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfsuContractsPending,
                                        'header-underline': showUnderlineForCheckListColumn(columnRFSUContractsPending)
                                    }"
                                >
                                    <span
                                        class="hover-pointer"
                                        (click)="
                                            openHeaderCheckListFilter(
                                                'rfsuContractsPending',
                                                getRFSUContractsPendingForColumn,
                                                'columnRFSUContractsPending',
                                                'RFSU Contracts Pending',
                                                columnRFSUContractsPending,
                                                false,
                                                true
                                            )
                                        "
                                        >RFSU Contracts Pending</span
                                    >
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.rfsuContractsPending }"
                                >
                                    {{ element.rfsuContractsPending | breakLine: ',' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="contractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.contract
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'contractor',
                                            getContractors,
                                            'columnContractor',
                                            'Contractor',
                                            columnContractor,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnContractor)
                                            }"
                                            >Contractor</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'contractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.contract }"
                                >
                                    {{ element.contractor }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="designStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.designStatus
                                    }"
                                    class="hover-pointer alignleft"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'designStatus',
                                            getStatuses,
                                            'columnDesignStatus',
                                            'Status',
                                            columnDesignStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                hidecells: !showHideColumns.designStatus,
                                                'header-underline':
                                                    columnDesignStatus && columnDesignStatus.items.length > 0
                                            }"
                                        >
                                            Design Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'designStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.designStatus }"
                                    *matCellDef="let element"
                                >
                                    {{ element.designStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="stepStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.stepStatus
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'currentStep',
                                            getStepStatuses,
                                            'columnStepStatus',
                                            'IPIMS Step',
                                            columnStepStatus,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnStepStatus)
                                            }"
                                            >IPIMS Step</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'currentStep'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.stepStatus }"
                                >
                                    {{ element.type !='RFI (iPIMS)' ? "N/A" : element.currentStep  == 0 ? "" : element.currentStep }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="validationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.validationStatus
                                    }"
                                    class="hover-pointer alignleft"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'validationStatus',
                                            getValidationStatus,
                                            'columnValidationStatus',
                                            'Validation Status',
                                            columnValidationStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                hidecells: !showHideColumns.validationStatus,
                                                'header-underline':
                                                    columnValidationStatus && columnValidationStatus.items.length > 0
                                            }"
                                        >
                                            Validation Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'validationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.validationStatus }"
                                    *matCellDef="let element"
                                >
                                    {{ element.validationStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="implementationStatus">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.implementationStatus
                                    }"
                                    class="hover-pointer alignleft"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'implementationStatus',
                                            getImplementationStatuses,
                                            'columnImplementationStatus',
                                            'Implementation Status',
                                            columnImplementationStatus,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                hidecells: !showHideColumns.implementationStatus,
                                                'header-underline':
                                                    columnImplementationStatus &&
                                                    columnImplementationStatus.items.length > 0
                                            }"
                                        >
                                            Implementation Status</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'implementationStatus'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.implementationStatus }"
                                    *matCellDef="let element"
                                >
                                    {{ element.implementationStatus }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="latestNBResponsiblePersonComment">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.responsiblePerson
                                    }"
                                    class="hover-pointer alignleft"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'latestNBResponsiblePersonComment',
                                            getNBResponsiplePersonComments,
                                            'columnNBResponsiblePersonComments',
                                            'Responsible Person',
                                            columnNBResponsiblePersonComments,
                                            true,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                hidecells: !showHideColumns.responsiblePerson,
                                                'header-underline':
                                                columnNBResponsiblePersonComments &&
                                                columnNBResponsiblePersonComments.items.length > 0
                                            }"
                                        >
                                            Responsible Person</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'latestNBResponsiblePersonComment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.responsiblePerson }"
                                    *matCellDef="let element"
                                >                             
                                    <div style="display: flex; justify-content: space-between;"> 
                                        <div
                                            (click)="openNBResponsiblePersonCommentPopup(element, $event)"
                                            class="comment-description"
                                        >
                                    
                                            <div *ngIf="element.latestNBResponsiblePersonComment && isNBResponsiblePerson; else notShow">
                                                <a
                                                    ><span
                                                        [innerHTML]="element.latestNBResponsiblePersonComment | formatMentions: element.mentions"
                                                    ></span
                                                ></a>
                                            </div>
                                            <div *ngIf="!isNBResponsiblePerson">
                                                <span
                                                    [innerHTML]="element.latestNBResponsiblePersonComment | formatMentions: element.mentions"
                                                ></span>
                                            </div>
                                            <ng-template #notShow>
                                                <button
                                                    type="button"
                                                    *ngIf="isNBResponsiblePerson"
                                                    mat-stroked-button
                                                    class="add-comment"
                                                >
                                                    Add
                                                </button>
                                            </ng-template>
                                        </div>
                                        
                                    </div>
                                
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fieldImplementationContractor">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.fieldImplementationContractor
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'fieldImplementationContractor',
                                            getFieldImplementationContractors,
                                            'columnFieldImplementationContractor',
                                            'Field Implementation Contractor',
                                            columnFieldImplementationContractor,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnFieldImplementationContractor)
                                            }"
                                            >Field Implementation Contractor</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'fieldImplementationContractor'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <span *ngIf="!fieldImplementationAccess()">
                                    <td 
                                        mat-cell
                                        [ngClass]="{ hidecells: !showHideColumns.fieldImplementationContractor }"
                                        *matCellDef="let element"
                                    >
                                        {{ element.fieldImplementationContractor }}
                                    </td>
                                </span>
                                <span *ngIf="fieldImplementationAccess()">
                                    <td 
                                        mat-cell
                                        *matCellDef="let element"
                                        [ngClass]="{ hidecells: !showHideColumns.fieldImplementationContractor }"
                                        (contextmenu)="fieldImplementationContraHistory($event,element.id)"
                                    >                                    
                                        <mat-select                               
                                        matNativeControl
                                        [(value)]="element.fieldImplementationContractor" 
                                        (selectionChange)="updateFieldImplementationContractor($event, element.id)"                                
                                        >
                                            <mat-option *ngFor="let fieldcontractor of fieldImplementationContractors" [value]="fieldcontractor">
                                                {{ fieldcontractor }}
                                            </mat-option>
                                            <mat-option *ngIf="!fieldImplementationContractors.includes(element.fieldImplementationContractor)" [value]="element.fieldImplementationContractor">
                                                {{ element.fieldImplementationContractor }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </span>
                            </ng-container>
                            <ng-container matColumnDef="priority">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.priority
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'priority',
                                            getPriorities,
                                            'columnPriority',
                                            'Priority',
                                            columnPriority,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnPriority)
                                            }"
                                            >Priority</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'Priority'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.priority }"
                                    *matCellDef="let element"
                                >
                                    {{ element.priority }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="rfiOriginator">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.rfiOriginator
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'originatingPersonName',
                                            getRFIOriginators,
                                            'columnRFIOriginator',
                                            'RFI Originator',
                                            columnRFIOriginator,
                                            true,
                                            true
                                        )
                                    "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnRFIOriginator)
                                            }"
                                            >RFI Originator</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'originatingPersonName'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td 
                                    mat-cell
                                    [ngClass]="{ hidecells: !showHideColumns.rfiOriginator }"
                                    *matCellDef="let element"
                                >
                                    {{ element.originatingPersonName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="expectedTimeOfCompletion">
                                <th
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{
                                        hidecells: !showHideColumns.expectedTimeOfCompletion,
                                        'header-filtered': showUnderlineForCalendarColumn(columnExpectedTimeOfCompletion)
                                    }"
                                    class="hover-pointer"
                                    (click)="
                                    openHeaderDateFilter(
                                        'expectedTimeOfCompletion',
                                        'columnExpectedTimeOfCompletion',
                                        'ETC',
                                        columnExpectedTimeOfCompletion
                                    )
                                "
                                >
                                    <div class="sort-container center">
                                        <span
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCalendarColumn(columnExpectedTimeOfCompletion)
                                            }"
                                            >ETC</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'expectedTimeOfCompletion'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    (click)="openETCDatepicker($event,element, 'expectedTimeOfCompletion')"
                                    mat-cell
                                    *matCellDef="let element; let idx = index"
                                    (contextmenu)="expectedTimeofCompletionHistory($event,element.id)"
                                    [ngClass]="{
                                        'cursor-pointer': true,
                                        'row-underline': element.expectedTimeOfCompletion != null ? true : false,
                                        hidecells: !showHideColumns.expectedTimeOfCompletion
                                    }"
                                >
                                    {{ element.expectedTimeOfCompletion != null ? (element.expectedTimeOfCompletion | date: 'd/MMM/yy') : "TBD" }}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="latestComment" stickyEnd>
                                <th
                                    app-col-resize
                                    mat-header-cell
                                    disableClear
                                    *matHeaderCellDef
                                    [ngClass]="{ hidecells: !showHideColumns.comment }"
                                >
                                    <div class="sort-container">
                                        <span
                                            class="hover-pointer"
                                            [ngClass]="{
                                                'header-underline': showUnderlineForCheckListColumn(columnComments)
                                            }"
                                            (click)="
                                                openHeaderCheckListFilter(
                                                    'comment',
                                                    getComments,
                                                    'columnComments',
                                                    'Comment',
                                                    columnComments,
                                                    false,
                                                    true,
                                                    true
                                                )
                                            "
                                            >Comment</span
                                        >
                                        <div class="sort-icon" *ngIf="sortBy === 'comment'">
                                            <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                        </div>
                                    </div>
                                </th>
                                <td
                                    mat-cell
                                    *matCellDef="let element"
                                    [ngClass]="{ hidecells: !showHideColumns.comment }"
                                    [ngStyle]="{ 'max-width': showHideColumns.title ? '468px' : '' }"
                                >
                                    <div (click)="openCommentPopup(element, $event)">
                                        <div *ngIf="element.comment && !isReadOnly; else notShow">
                                            <a
                                                ><span
                                                    [innerHTML]="element.comment | formatMentions: element.mentions"
                                                ></span
                                            ></a>
                                        </div>
                                        <div *ngIf="isReadOnly">
                                            <span
                                                [innerHTML]="element.comment | formatMentions: element.mentions"
                                            ></span>
                                        </div>
                                        <ng-template #notShow>
                                            <button
                                                type="button"
                                                *ngIf="!isReadOnly"
                                                mat-stroked-button
                                                class="add-comment"
                                            >
                                                Add
                                            </button>
                                        </ng-template>
                                    </div>
                                    <div
                                        [ngClass]="{ notactive: !element.comment, active: element.comment }"
                                        class="comment-hist"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            (click)="openHistoryPopup(element)"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path
                                                d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                            />
                                        </svg>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </app-mat-table-sticky-header>
                </div>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 50]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    min="1"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>

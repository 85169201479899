<div class="schedule-container" *ngIf="schedule$ | async">
    <h3>Schedule</h3>
    <div class="schedtimeln">
        <form [formGroup]="scheduleForm">
            <div class="schedtimeln-content">
                <div class="schedtimeln-line"></div>
                <div *ngFor="let event of events" class="schedtimeln-item">
                    <div class="timeline-icon"></div>
                    <mat-form-field appearance="standard">
                        <input readonly [formControl]="event.control" matInput [matDatepicker]="picker" />
                        <mat-datepicker-toggle [disabled]="true" matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="events.length > 0" class="schedtimeln-item">
                    <div class="timeline-icon goocmilestone"></div>
                    <mat-form-field appearance="standard" class="goocmilestone">
                        <input readonly formControlName="goocMilestone" matInput [matDatepicker]="picker" />
                        <mat-datepicker-toggle [disabled]="true" matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="isLoading$ | async" class="upload-log-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>

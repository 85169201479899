import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../../base.component';
import { DcnDTO } from 'src/app/store/change-management-upload-dcn/model';

@Component({
    selector: 'app-impacted-tags-popup',
    templateUrl: './impacted-tags-popup.component.html',
    styleUrls: ['./impacted-tags-popup.component.scss'],
})
export class ImpactedTagsPopupComponent extends BaseComponent implements OnInit {
    element: DcnDTO;
    action: (impactedTags: string[]) => void;
    impactedTags: string[];
    impactedTagsText: string;

    constructor(public dialogRef: MatDialogRef<ImpactedTagsPopupComponent>, @Inject(MAT_DIALOG_DATA) data) {
        super();

        this.element = data.element;
        this.impactedTags = data.element.impactedTags !== null ? data.element.impactedTags : [];
        this.action = data.action;

        this.impactedTagsText = this.impactedTags.join(', ');
    }

    ngOnInit() {
        this.dialogRef.afterClosed().subscribe((applyChanges: boolean) => {
            if (applyChanges) {
                let tags = this.impactedTagsText.split(',').map((x) => x.trim());
                this.action(tags);
            }
        });
    }

    close(applyChanges: boolean) {
        this.dialogRef.close(applyChanges);
    }
}

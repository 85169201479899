<div class="certstatus-container">
    <div class="pli-validation-container result-table margin-right20" *ngIf="pliValidation$ | async">
        <h3>PLI Validation</h3>
        <button
            type="button"
            class="sct-button sct-button-light"
            (click)="save()"
            [disabled]="!pliValidationForm.valid || (isPLIValidationLoading$ | async)"
        >
            save pli validation
        </button>
        <table [formGroup]="pliValidationForm">
            <thead>
                <tr>
                    <th>Total</th>
                    <td>
                        <mat-form-field>
                            <input matInput type="number" min="0" formControlName="total" />
                        </mat-form-field>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Reviewed</th>
                    <td>{{ pliValidationForm.controls.changed.value + pliValidationForm.controls.noChange.value }}</td>
                </tr>
                <tr>
                    <th>Changed</th>
                    <td>
                        <mat-form-field>
                            <input matInput type="number" min="0" formControlName="changed" />
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <th>No Change</th>
                    <td>
                        <mat-form-field>
                            <input matInput type="number" min="0" formControlName="noChange" />
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <th>% Complete</th>
                    <td>{{ calculatePercentCompleted() }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="isPLIValidationLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="change-validation-container result-table margin-right20" *ngIf="changeValidation$ | async">
        <h3>Change Validation (Area)</h3>
        <table>
            <thead>
                <tr>
                    <th>Total</th>
                    <td>{{ (changeValidation$ | async).Total }}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Not Started</th>
                    <td>{{ (changeValidation$ | async).NotStarted }}</td>
                </tr>
                <tr>
                    <th>In Progress</th>
                    <td>{{ (changeValidation$ | async).InProgress }}</td>
                </tr>
                <tr>
                    <th>Completed</th>
                    <td>{{ (changeValidation$ | async).Completed }}</td>
                </tr>
                <tr>
                    <th>% Complete</th>
                    <td>{{ (changeValidation$ | async).PercentCompleted }}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="isChangeValidationLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="aqvd-request-status-container result-table" *ngIf="(aqvdRequestStatusItems$ | async).length">
        <h3>A-QVD Request Status for Not Started QVDs</h3>
        <table>
            <thead>
                <tr>
                    <td *ngFor="let item of aqvdRequestStatusItems$ | async">{{ item.key }}</td>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngFor="let item of aqvdRequestStatusItems$ | async">{{ item.value }}</td>
                    <th>{{ aqvdRequestStatusTotal$ | async }}</th>
                </tr>
            </tbody>
        </table>
        <div *ngIf="isAQVDRequestStatusLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
</div>

<div class="result-table margin-right20" *ngIf="earlyWalkdowns$ | async">
    <h3>Early Walkdowns</h3>
    <button
        type="button"
        class="sct-button sct-button-light"
        (click)="save()"
        [disabled]="!earlyWalkdownsForm.valid || (isLoading$ | async)"
    >
        save early walkdowns
    </button>
    <table [formGroup]="earlyWalkdownsForm">
        <thead>
            <tr>
                <th>Total</th>
                <td>
                    {{ earlyWalkdownsForm.controls.scheduled.value + earlyWalkdownsForm.controls.complete.value }}
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Scheduled</th>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="scheduled" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <th>Complete</th>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="complete" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <th>% Complete</th>
                <td>{{ calculatePercentCompleted() }}</td>
            </tr>
            <tr>
                <th>PLI Raised</th>
                <td>
                    <mat-form-field>
                        <input matInput type="number" min="0" formControlName="pliRaised" />
                    </mat-form-field>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="isLoading$ | async" class="upload-log-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>

import { ChangeManagementState, ChangeManagementDataPagination, ChangeManagementFilter } from './model';
import { ChangeManagementActions, ChangeManagementActionTypes } from './actions';
import { Zone } from '../../enums';

const initialState: ChangeManagementState = {
    dataPagination: new ChangeManagementDataPagination(),
    filter: new ChangeManagementFilter(),
    isLoading: false,
    makeRequest: false,
};

export function reducer(state = initialState, action: ChangeManagementActions): ChangeManagementState {
    switch (action.type) {
        case ChangeManagementActionTypes.ChangeManagementFilterRequest: {
            return {
                ...state,
                isLoading: true,
                makeRequest: false,
            };
        }
        case ChangeManagementActionTypes.ChangeManagementSetMakeRequest: {
            return {
                ...state,
                makeRequest: true,
            };
        }
        case ChangeManagementActionTypes.ChangeManagementFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case ChangeManagementActionTypes.ChangeManagementAddCommentError:
        case ChangeManagementActionTypes.ChangeManagementAddBulkCommentError:
        case ChangeManagementActionTypes.ChangeManagementDeleteCommentSuccess:
        case ChangeManagementActionTypes.ChangeManagementDeleteCommentError:
        case ChangeManagementActionTypes.ChangeManagementExportToExcelSuccess:
        case ChangeManagementActionTypes.ChangeManagementExportToExcelError:
        case ChangeManagementActionTypes.ChangeManagementFieldImplementationContractorError:
        case ChangeManagementActionTypes.ChangeManagementAddExpectedClosureDateError:
        case ChangeManagementActionTypes.ChangeManagementAddExpectedClosureDateRequest:
        case ChangeManagementActionTypes.ChangeManagementFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ChangeManagementActionTypes.ChangeManagementFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(action.payload.value)
                            ? [...action.payload.value]
                            : action.payload.value && typeof action.payload.value === 'object'
                            ? { ...action.payload.value }
                            : action.payload.value,
                    },
                };
            }
        }
        case ChangeManagementActionTypes.ChangeManagementFilterReset: {
            const newFilter = new ChangeManagementFilter();
            newFilter.sortBy = state.filter.sortBy;
            newFilter.direction = state.filter.direction;
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case ChangeManagementActionTypes.ChangeManagementAddBulkCommentRequest:
        case ChangeManagementActionTypes.ChangeManagementDeleteCommentRequest:
        case ChangeManagementActionTypes.ChangeManagementExportToExcelRequest:
        case ChangeManagementActionTypes.ChangeManagementUpdateFieldImplementationContractorRequest:    
        case ChangeManagementActionTypes.ChangeManagementAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ChangeManagementActionTypes.ChangeManagementAddCommentSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.id === action.payload.id);
            const responsibleIndex = state.dataPagination.items.findIndex((x) => {
                   if(action.payload.zone === Zone.ResponsiblePerson) {
                    return x.id === action.payload.id;
                   }
            });

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex && action.payload.zone !== Zone.ResponsiblePerson) {
                            return {
                                ...d,
                                comment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        if (i === responsibleIndex) {
                            return {
                                ...d,
                                latestNBResponsiblePersonComment: action.payload.description,
                                mentions: action.payload.mentions,
                            };
                        }
                        return d;
                    }),
                },
            };
        }
        case ChangeManagementActionTypes.ChangeManagementAddBulkCommentSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((item) => ({
                        ...item,
                        comment: action.payload.description,
                        mentions: action.payload.mentions,
                    })),
                },
            };
        }

        case ChangeManagementActionTypes.ChangeManagementFieldImplementationContractorSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.id === action.payload.id);

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                fieldImplementationContractor: action.payload.fieldImplementationContractor                                
                            };
                        }
                        return d;
                    }),
                },
            };
        }

        case ChangeManagementActionTypes.ChangeManagementAddExpectedClosureDateSuccess: {
            const itemIndex = state.dataPagination.items.findIndex((x) => x.id === action.payload.rowId);

            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    ...state.dataPagination,
                    items: state.dataPagination.items.map((d, i) => {
                        if (i === itemIndex) {
                            return {
                                ...d,
                                expectedTimeOfCompletion: action.payload.expectedClosureDate,
                            };
                        }
                        return d;
                    }),
                },
            };
        }

       
        default:
            return state;
    }
}

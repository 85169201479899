import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { PLIValidationSaveRequest } from 'src/app/store/reports/pre-mc/actions';
import { PLIValidationDTO } from 'src/app/store/reports/pre-mc/model';

@Component({
    selector: 'app-certification-status',
    templateUrl: './certification-status.component.html',
    styleUrls: ['./certification-status.component.scss'],
})
export class CertificationStatusComponent {
    pliValidationForm: UntypedFormGroup;

    isPLIValidationLoading$ = this.store.select(
        (state) => state.preMCState.certificationStatus.pliValidation.isLoading
    );
    pliValidation$ = this.store.select((state) => state.preMCState.certificationStatus.pliValidation.entity);
    changeValidation$ = this.store.select((state) => state.preMCState.certificationStatus.changeValidation.entity);
    isChangeValidationLoading$ = this.store.select(
        (state) => state.preMCState.certificationStatus.changeValidation.isLoading
    );
    aqvdRequestStatusItems$ = this.store.select(
        (state) => state.preMCState.certificationStatus.aqvdRequestStatus.items
    );
    isAQVDRequestStatusLoading$ = this.store.select(
        (state) => state.preMCState.certificationStatus.aqvdRequestStatus.isLoading
    );
    aqvdRequestStatusTotal$ = this.store.select(
        (state) => state.preMCState.certificationStatus.aqvdRequestStatus.total
    );

    constructor(private store: Store<ApplicationState>, private formService: FormService) {
        this.pliValidationForm = this.formService.createSimpleForm(new PLIValidationDTO());
    }

    ngOnInit() {
        this.pliValidation$
            .pipe(filter((pliValidation) => !!pliValidation))
            .subscribe((pliValidation) => this.pliValidationForm.patchValue(pliValidation));
    }

    save() {
        this.store.dispatch(new PLIValidationSaveRequest(this.pliValidationForm.value));
    }

    calculatePercentCompleted() {
        const reviewed = this.pliValidationForm.controls.changed.value + this.pliValidationForm.controls.noChange.value;
        const total = this.pliValidationForm.controls.total.value;

        const result = total ? ((reviewed / total) * 100).toFixed(2) : 0;
        return result ? `${result}%` : 0;
    }
}

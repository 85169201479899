import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { plannedGteCompletedValidator } from 'src/app/validators/plannedGteCompleted.validator';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { EarlyWorksSaveRequest } from 'src/app/store/reports/pre-mc/actions';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-early-works',
    templateUrl: './early-works.component.html',
    styleUrls: ['./early-works.component.scss'],
})
export class EarlyWorksComponent implements OnInit {
    earlyWorksForm: UntypedFormGroup;

    isLoading$ = this.store.select((state) => state.preMCState.earlyWorks.isLoading);
    earlyWorks$ = this.store.select((state) => state.preMCState.earlyWorks.entity);

    constructor(private fb: UntypedFormBuilder, private store: Store<ApplicationState>) {
        this.earlyWorksForm = this.fb.group(
            {
                electrical_FiberOpticChecksCompleted: [0],
                electrical_FiberOpticChecksPlanned: [0],
                electrical_HeatTraceColdCheckCompleted: [0],
                electrical_HeatTraceColdCheckPlanned: [0],
                electrical_HeatTraceHotCheckCompleted: [0],
                electrical_HeatTraceHotCheckPlanned: [0],
                iC_ColdLoopChecksCompleted: [0],
                iC_ColdLoopChecksPlanned: [0],
                iC_LouverActuationTestingCompleted: [0],
                iC_LouverActuationTestingPlanned: [0],
                smP_PulleyAlignmentCompleted: [0],
                smP_PulleyAlignmentPlanned: [0],
                smP_PumpAlignmentCompleted: [0],
                smP_PumpAlignmentPlanned: [0],
            },
            { validators: [plannedGteCompletedValidator()] }
        );
    }

    ngOnInit() {
        this.earlyWorks$
            .pipe(filter((earlyWorks) => !!earlyWorks))
            .subscribe((earlyWorks) => this.earlyWorksForm.patchValue(earlyWorks));
    }

    calculatePercentCompleted(plannedControlName: string, completeControlName: string) {
        const plannedValue = this.earlyWorksForm.controls[plannedControlName].value;
        const completeValue = this.earlyWorksForm.controls[completeControlName].value;

        const result = plannedValue && completeValue ? ((completeValue / plannedValue) * 100).toFixed(2) : 0;
        return result ? `${result}%` : 0;
    }

    save() {
        this.store.dispatch(new EarlyWorksSaveRequest(this.earlyWorksForm.value));
    }
}

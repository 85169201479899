import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
    userName: string;
    constructor(private authService: MsalService) {}

    ngOnInit() {
        this.userName = this.authService.instance.getActiveAccount().username;
    }
}

import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { intersectionBy, uniqBy } from 'lodash';
import { iif, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { formatToSelectOptions } from './multiselect-utils';
import { SelectOption } from './multiselect.types';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MultiselectComponent),
        },
    ],
})
export class MultiselectComponent extends BaseComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() placeholder: string = '';
    @Input() appearance: string = 'standard';
    @Input() label: string = '';
    @Input() panelWidth: string | number = null;
    @Input() required: boolean = false;
    @Input() single: boolean = false;
    @Input() selectAll: boolean = false;
    @Input()
    get errorMessage() {
        return this._errorMessage;
    }
    set errorMessage(value: string) {
        this._errorMessage = value;
        this.setupControlError(value);
    }
    @Input()
    get items() {
        return this._items;
    }
    set items(value: any[] | null) {
        this._items = formatToSelectOptions(value, this.labelFormat);
    }
    @Input() onSearch?: (searchText?: string, take?: number, page?: number) => Observable<any[]>;
    @Input() labelFormat?: (item: any) => string;

    readonly selectAllText: string = 'Select All';
    loading: boolean = false;
    opened: boolean = false;
    optionHeight = 50;
    dropdownHeight: number = 0;
    inputControl = new FormControl<string | string[]>('');
    localOptions$: Observable<SelectOption[]>;
    serverOptions$: Observable<SelectOption[]>;
    filteredOptions$: Observable<SelectOption[]>;

    private _errorMessage: string = '';
    private _items: Array<SelectOption> = [];
    private _selectedItems: Array<SelectOption> = [];
    private _touched: boolean = false;
    private _disabled: boolean = false;

    constructor() {
        super();
    }

    // Lifecycle
    ngOnInit(): void {
        this.filteredOptions$ = this.inputControl.valueChanges.pipe(
            startWith<string>(''),
            filter((x) => x != null && x != undefined),
            tap(() => {
                this.loading = !!this.onSearch;
            }),
            debounceTime(!!this.onSearch ? 500 : 0),
            mergeMap((filter) =>
                iif(
                    () => !!this.onSearch,
                    this.filterOptionsWithSearchAsync(filter),
                    this.filterLocalOptionsAsync(filter)
                ).pipe(
                    tap((v) => {
                        this.dropdownHeight = v.length > 5 ? 5 * this.optionHeight : this.optionHeight * v.length;
                        this.loading = false;
                    })
                )
            )
        );
    }

    ngOnChanges({ items, labelFormat }: SimpleChanges): void {
        if (items && labelFormat) {
            this._items = formatToSelectOptions(items.currentValue, labelFormat.currentValue);
        }
    }

    // ControlValueAccessor
    onChange = (_: any) => {};
    onTouched = () => {};

    writeValue(value: any[] | null): void {
        this._selectedItems = formatToSelectOptions(value, this.labelFormat);
        this.setupInputControlSelectedValue();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled;
        if (isDisabled) {
            this.inputControl.disable({ emitEvent: false });
        } else {
            this.inputControl.enable({ emitEvent: false });
        }
    }

    // Template callbacks
    onInputClear = () => {
        if (this._disabled) {
            return;
        }

        this.markAsTouched();
        if (!this.opened) {
            this.clearSelected();
        }
        this.inputControl.setValue('', { emitEvent: true });
    };

    onOptionClicked = (event: Event, data: SelectOption): void => {
        if (!this.single) {
            event.stopPropagation();
        }

        if (this._disabled) {
            return;
        }
        this.toggleSelection(data);
    };

    onAutocompleteOpened = () => {
        if (this._disabled) {
            return;
        }

        this.opened = true;
        this.markAsTouched();
        this.inputControl.setValue('');
    };

    onAutocompleteClosed = () => {
        if (this._disabled) {
            return;
        }

        this.opened = false;
        this.markAsTouched();
        this.setupInputControlSelectedValue();
    };

    isAllComplete(options: SelectOption[]): boolean {
        const seletedCount = intersectionBy(this._selectedItems, options, 'hash').length;
        return options.length === seletedCount;
    }

    isSomeComplete(options: SelectOption[]): boolean {
        const seletedCount = intersectionBy(this._selectedItems, options, 'hash').length;
        return seletedCount > 0 && seletedCount < options.length;
    }

    setAllComplete(options: SelectOption[], cheked: boolean) {
        if (!this.single) {
            event.stopPropagation();
        }

        if (this._disabled) {
            return;
        }

        this.setGroupSelection(options, cheked);
    }

    // Custom logic
    setupInputControlSelectedValue = (emitEvent: boolean = false) => {
        const selected = this._selectedItems.map((x) => x.label);
        this.inputControl.setValue(selected, { emitEvent });
        this.setupControlError(this._errorMessage);
    };

    setupControlError = (value: string) => {
        if (value) {
            this.inputControl.setErrors({ incorrect: true });
        } else {
            this.inputControl.setErrors(null);
        }
    };

    filterSortOptions = (options: SelectOption[], filter): SelectOption[] => {
        let selectedFiltered: SelectOption[] = [];
        let nonSelectedFiltered: SelectOption[] = [];

        for (let option of options) {
            if (option.label.toLowerCase().includes(filter.toLowerCase())) {
                if (this.isOptionSelected(option)) {
                    selectedFiltered.push(option);
                } else {
                    nonSelectedFiltered.push(option);
                }
            }
        }

        return [...selectedFiltered, ...nonSelectedFiltered];
    };

    attachSelectAll = (filter: string, options: SelectOption[]): SelectOption[] => {
        return this.selectAll && filter && !this.single
            ? [{ label: this.selectAllText } as SelectOption, ...options]
            : options;
    };

    filterLocalOptionsAsync = (filter: string): Observable<SelectOption[]> => {
        const result = this.attachSelectAll(filter, this.filterSortOptions(this._items, filter));
        return of(result);
    };

    filterOptionsWithSearchAsync = (filter: string): Observable<SelectOption[]> => {
        return this.onSearch
            ? this.onSearch(filter, 100000, 0).pipe(
                  map((value) => formatToSelectOptions(value, this.labelFormat)),
                  map((options) => this.filterSortOptions(options, filter)),
                  map((options) => this.attachSelectAll(filter, options)),
                  catchError((_) => of([]))
              )
            : of([]);
    };

    isOptionSelected = (selectionOption: SelectOption): boolean => {
        return this._selectedItems.findIndex((x) => x.hash === selectionOption.hash) >= 0;
    };

    clearSelected = (): void => {
        this._selectedItems = [];
        this.onChange([]);
    };

    toggleSelection = (selectedOption: SelectOption): void => {
        if (this.isOptionSelected(selectedOption)) {
            this._selectedItems = this._selectedItems.filter((x) => x.hash !== selectedOption.hash);
        } else {
            this._selectedItems = [...(this.single ? [] : this._selectedItems), selectedOption];
        }

        this.onChange(this._selectedItems.map((x) => x.value));
    };

    setGroupSelection = (selectedOptions: SelectOption[], checked: boolean): void => {
        if (checked) {
            this._selectedItems = uniqBy(
                [...this._selectedItems, ...selectedOptions.filter((x) => x.label !== this.selectAllText)],
                'hash'
            );
        } else {
            this._selectedItems = this._selectedItems.filter(
                (x) => selectedOptions.findIndex((y) => y.hash === x.hash) < 0
            );
        }

        this.onChange(this._selectedItems.map((x) => x.value));
    };

    markAsTouched() {
        if (!this._touched) {
            this.onTouched();
            this._touched = true;
        }
    }
}

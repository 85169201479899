import { Component, OnInit, EventEmitter, ViewChild, NgZone, Input } from '@angular/core';
import { BaseComponent } from '../base.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { ChangeManagementFilter, ChangeManagementDTO, ShowHideColumns } from 'src/app/store/change-management/model';
import { Constants } from 'src/app/constants';
import {
    Contractor,
    Waypoint,
    Milestone,
    OrderDirection,
    ChangeDocStatus,
    CheckListColumn,
    CalendarColumn,
    UserDetail,
    McMilestone,
    Gooc,
    RFO,
    TCOUser,
    StagedStartUpPriority,
} from 'src/app/store/common.model';
import { map, takeWhile, take, catchError } from 'rxjs/operators';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ToastService } from 'src/app/services/shared/toast.service';
import { ApplicationState } from 'src/app/store/model';
import { Store, select } from '@ngrx/store';
import {
    ChangeManagementFilterPropertyUpdate,
    ChangeManagementFilterReset,
    ChangeManagementFilterRequest,
    ChangeManagementAddCommentRequest,
    ChangeManagementExportToExcelRequest,
    ChangeManagementAddBulkCommentRequest,
    ChangeManagementDeleteCommentRequest,    
    ChangeManagementUpdateFieldImplementationContractorRequest,
    ChangeManagementAddExpectedClosureDateRequest,
} from 'src/app/store/change-management/actions';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { PopupSettings } from 'src/app/models/popup-settings';
import { CommentPopupComponent } from '../../modules/shared/components/comment-popup/comment-popup.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { RoleService } from 'src/app/services/shared/role.service';
import { ProjectTeamsService } from 'src/app/services/shared/project-teams.service';
import { Location } from '@angular/common';
import { changeTypeEnumToString } from 'src/app/extensions';
import { ChangeType, SubsystemScope, Zone } from 'src/app/enums';
import { HeaderDateFilter, HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from 'src/app/modules/shared/components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from 'src/app/modules/shared/components/filter/header-date-filter/header-date-filter.component';
import { CommentService } from 'src/app/services/api/webapi-services/comment.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';
import { FieldImplementationContractorHistoryComponent } from './field-implementation-contractor-history/field-implementation-contractor-history.component';
import { EtcHistoryComponent } from '../critical-rfi/etc-history/etc-history.component';
import { ClearableMatDatepickerComponent } from '../clearable-mat-datepicker/clearable-mat-datepicker.component';

@Component({
    selector: 'app-change-management',
    templateUrl: './change-management.component.html',
    styleUrls: ['./change-management.component.scss'],
})
export class ChangeManagementComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    projectTeamNames: string[] = [];
    projectTeamNamesCurrent: string[] = [];
    contractors: Contractor[] = [];
    changeTypes: string[] = [];
    turnoverGroups: string[] = [];
    disciplines: string[] = [];
    designStatuses: string[] = [];
    isLoading = false;
    sortBy: string;
    direction = OrderDirection.Desc;
    lockFilters = false;
    showFilterPanel=true;
    allowCommentsBulkUpdate = false;
    isNBResponsiblePerson = false;
    subsystem: string;
    rfo: string;
    milestones$ = this.store.select((state) => state.changeManagementState.filter.milestones);
    waypoints$ = this.store.select((state) => state.changeManagementState.filter.waypoints);
    prevWaypoints: Waypoint[] = [];
    //fieldImplementationContractors:string[] = ['K-500-182-CSG','K-500-182-ON','K-500-200-ON','K-500-200-ON-HIT','K-500-203-ON','K-600-047-ON','TCO-FGP-IT','K-500-182-CSG','K-500-200-HIT','K-500-203-TT', 'FGP Operations'];
    fieldImplementationContractors:string[] = [];
    npwStatuses: string[] = [ChangeDocStatus.inEngineering, ChangeDocStatus.toImplement, ChangeDocStatus.noPhysicalWork];
    npwChangeTypes: string[] = [
        changeTypeEnumToString(ChangeType.DCN),
        changeTypeEnumToString(ChangeType.RFI),
        changeTypeEnumToString(ChangeType.NPW),
        changeTypeEnumToString(ChangeType.NCR),
        changeTypeEnumToString(ChangeType.PCN),
        //changeTypeEnumToString(ChangeType.CallOff),
        //changeTypeEnumToString(ChangeType.SCCallOff),
        changeTypeEnumToString(ChangeType.TQ),
        changeTypeEnumToString(ChangeType.MACDCN),
        changeTypeEnumToString(ChangeType.ContractorNCR),
        changeTypeEnumToString(ChangeType.TMOC),
        changeTypeEnumToString(ChangeType.Surveillance),
        changeTypeEnumToString(ChangeType.RFIIPIMS),
        changeTypeEnumToString(ChangeType.SID),
    ];
    npwRedzoneChangeTypes: string[] = [
        changeTypeEnumToString(ChangeType.DCN),
        changeTypeEnumToString(ChangeType.RFI),
        changeTypeEnumToString(ChangeType.NPW),
        changeTypeEnumToString(ChangeType.NCR),
        changeTypeEnumToString(ChangeType.PCN),
        //changeTypeEnumToString(ChangeType.CallOff),
        changeTypeEnumToString(ChangeType.TQ),
        changeTypeEnumToString(ChangeType.MACDCN),
        changeTypeEnumToString(ChangeType.ContractorNCR),
        changeTypeEnumToString(ChangeType.Surveillance),
        changeTypeEnumToString(ChangeType.TMOC),
        changeTypeEnumToString(ChangeType.RFIIPIMS),
        changeTypeEnumToString(ChangeType.SID),
    ];
    npwBluezoneChangeTypes: string[] = [
        changeTypeEnumToString(ChangeType.DCN),
        changeTypeEnumToString(ChangeType.RFI),
        changeTypeEnumToString(ChangeType.NPW),
        changeTypeEnumToString(ChangeType.NCR),
        changeTypeEnumToString(ChangeType.PCN),
        //changeTypeEnumToString(ChangeType.CallOff),
        //changeTypeEnumToString(ChangeType.SCCallOff),
        changeTypeEnumToString(ChangeType.TQ),
        changeTypeEnumToString(ChangeType.MACDCN),
        changeTypeEnumToString(ChangeType.ContractorNCR),
        changeTypeEnumToString(ChangeType.TMOC),
        changeTypeEnumToString(ChangeType.Surveillance),
        changeTypeEnumToString(ChangeType.RFIIPIMS),
        //changeTypeEnumToString(ChangeType.SID),
    ];
    rfos: RFO[] = [];
    mcMilestones: McMilestone[];
    goocs: Gooc[];
    sysOwners: TCOUser[] = [];
    mcEngineers: TCOUser[] = [];
    systemOwnersFilter: number[];
    mcEngineersFilter: number[];
    contractorsFilter: Contractor[];
    stagedStartUpPriorityFilter: StagedStartUpPriority[];
    data: ChangeManagementDTO[] = [];
    autocompleteDisplayedColumns = ['name', 'description'];
    turnoverGroupsAutocompleteDisplayedColumns = ['id', 'name'];
    contractorsAutocompleteDisplayedColumns = ['contractNo', 'contract'];
    stagedUpPriorityAutocompleteDisplayedColumns = ['priority', 'priorityName'];
    goocAutocompleteDisplayedColumns = ['no', 'name'];
    mcMilestoneAutocompleteDisplayedColumns = ['name'];
    subsystemAutocompleteDisplayedColumns = ['id', 'name'];
    todayDate = new Date();
    resultsLength = 0;
    pageSize = 25;
    displayedColumns = [
        'id',
        'type',
        'discipline',
        'title',
        'date',
        'areaBreakDown',
        'rfo',
        'rfoStatus',
        'subsystem',
        'signedSubsystem',
        'rfsuContractor',
        'rfsuContractsPending',
        'linkedIWPs',
        'linkedCOFs',        
        'walkdownForecast',        
        //'rfsuPlan',   
        'walkdownActual',     
        'contractor',
        'mcActual',   
        'designStatus',
        'stepStatus',
        'validationStatus',
        'priority',
        'rfiOriginator',
        'createdDate',
        'revision',
        'rfsuForecast',
        'rfoForecast', 
        'implementationStatus',
        'fieldImplementationContractor',
        'exceptionNumber',
        'expectedTimeOfCompletion',
        'latestNBResponsiblePersonComment',                
        'latestComment'        
    ];

    showHideColumns = new ShowHideColumns();
    changeManagementFilter$ = this.store.select((store) => store.changeManagementState.filter);
    changeManagementLoader$ = this.store.select((store) => store.changeManagementState.isLoading);
    changeManagementDataPagination$ = this.store.select((store) => store.changeManagementState.dataPagination);
    columnChangeType$ = this.store.select((state) => state.changeManagementState.filter.columnChangeType);
    columnTitle$ = this.store.select((state) => state.changeManagementState.filter.columnTitle);
    columnAreaBreakDown$ = this.store.select((state) => state.changeManagementState.filter.columnAreaBreakDown);
    columnExceptionNumber$ = this.store.select((state) => state.changeManagementState.filter.columnExceptionNumber);
    columnValidationStatus$ = this.store.select((state) => state.changeManagementState.filter.columnValidationStatus);
    columnRFO$ = this.store.select((state) => state.changeManagementState.filter.columnRFO);
    columnRFOStatus$ = this.store.select((state) => state.changeManagementState.filter.columnRFOStatus);
    columnId$ = this.store.select((state) => state.changeManagementState.filter.columnId);
    columnSubsystem$ = this.store.select((state) => state.changeManagementState.filter.columnSubsystem);
    columnSignedSubsystem$ = this.store.select((state) => state.changeManagementState.filter.columnSignedSubsystem);
    columnLinkedIWPs$ = this.store.select((state) => state.changeManagementState.filter.columnLinkedIWPs);
    columnLinkedCOFs$ = this.store.select((state) => state.changeManagementState.filter.columnLinkedCOFs);
    columnRFSUContractor$ = this.store.select((state) => state.changeManagementState.filter.columnRFSUContractor);
    columnRFSUContractsPending$ = this.store.select((state) => state.changeManagementState.filter.columnRFSUContractsPending);
    columnContractor$ = this.store.select((state) => state.changeManagementState.filter.columnContractor);
    columnFieldImplementationContractor$ = this.store.select((state) => state.changeManagementState.filter.columnFieldImplementationContractor);
    columnDiscipline$ = this.store.select((state) => state.changeManagementState.filter.columnDiscipline);
    columnComments$ = this.store.select((state) => state.changeManagementState.filter.columnComments);
    columnDesignStatus$ = this.store.select((state) => state.changeManagementState.filter.columnDesignStatus);
    columnPriority$ = this.store.select((state) => state.changeManagementState.filter.columnPriority);
    columnRFIOriginator$ = this.store.select((state) => state.changeManagementState.filter.columnRFIOriginator);
    columnRevision$ = this.store.select((state) => state.changeManagementState.filter.columnRevision);
    columnRFOForecast$ = this.store.select((state) => state.changeManagementState.filter.columnRFOForecast);
    columnSystemOwner$ = this.store.select((state) => state.changeManagementState.filter.columnSystemOwner);
    columnExpectedTimeOfCompletion$ = this.store.select((state) => state.changeManagementState.filter.columnExpectedTimeOfCompletion);
    columnImplementationStatus$ = this.store.select(
        (state) => state.changeManagementState.filter.columnImplementationStatus
    );
    columnNBResponsiblePersonComments$ = this.store.select(
        (state) => state.changeManagementState.filter.columnNBResponsiblePersonComments
    );
    columnDate$ = this.store.select((state) => state.changeManagementState.filter.columnDate);
    columnRFSUForecast$ = this.store.select((state) => state.changeManagementState.filter.columnRFSUForecast);
    columnWalkdownForecast$ = this.store.select((state) => state.changeManagementState.filter.columnWalkdownForecast);
    columnCreatedDate$ = this.store.select((state) => state.changeManagementState.filter.columnCreatedDate);
    columnRFSUPlan$ = this.store.select((state) => state.changeManagementState.filter.columnRFSUPlan);
    columnMcActual$ = this.store.select((state) => state.changeManagementState.filter.columnMcActual);
    columnWalkdownActual$ = this.store.select((state) => state.changeManagementState.filter.columnWalkdownActual);
    columnStepStatus$ = this.store.select((state) => state.changeManagementState.filter.columnStepStatus);
    sortBy$ = this.store.select((state) => state.changeManagementState.filter.sortBy);
    direction$ = this.store.select((state) => state.changeManagementState.filter.direction);

    makeRequest$ = this.store.select((state) => state.changeManagementState.makeRequest);

    contractors$ = this.store.select((state) => state.changeManagementState.filter.contractors);
    prevContractors: Contractor[] = [];
    stagedStartUpPriority$ = this.store.select((state)=> state.changeManagementState.filter.stagedStartUpPriority);
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setWaypointInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setTurnoverInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setGoocInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFOInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setMcMilestoneInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setStagedStartUpPriorityInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setAreaBreakdownInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setValidationStatusInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    setRFSUContractorsInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isReadOnly = false;
    isNewPage = false;

    columnChangeType: CheckListColumn = null;
    columnTitle: CheckListColumn = null;
    columnAreaBreakDown: CheckListColumn = null;
    columnExceptionNumber: CheckListColumn = null;
    columnValidationStatus: CheckListColumn = null;
    columnRFO: CheckListColumn = null;
    columnRFOStatus: CheckListColumn = null;
    columnId: CheckListColumn = null;
    columnSubsystem: CheckListColumn = null;
    columnSignedSubsystem: CheckListColumn = null;
    columnLinkedIWPs: CheckListColumn = null;
    columnLinkedCOFs: CheckListColumn = null;
    columnRFSUContractor: CheckListColumn = null;
    columnRFSUContractsPending: CheckListColumn = null;
    columnContractor: CheckListColumn = null;
    columnFieldImplementationContractor: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnDesignStatus: CheckListColumn = null;
    columnPriority: CheckListColumn = null;
    columnRFIOriginator: CheckListColumn = null;
    columnSystemOwner: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnRFOForecast: CalendarColumn = null;
    columnExpectedTimeOfCompletion: CalendarColumn = null;
    columnImplementationStatus: CheckListColumn = null;
    columnNBResponsiblePersonComments: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnDate: CalendarColumn = null;
    columnRFSUForecast: CalendarColumn = null;
    columnWalkdownForecast: CalendarColumn = null;
    columnCreatedDate: CalendarColumn = null;
    columnRFSUPlan: CalendarColumn = null;
    columnMcActual: CalendarColumn = null;
    columnWalkdownActual: CalendarColumn = null;
    columnStepStatus: CheckListColumn = null;
    changeType: string;

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() blueScreen: boolean = false;
    @Input() blueSubsystem: string;
    @Input() blueRFO: string;
    @Input() blueChangeType: string;
    @Input() blueAllowComments: boolean = true;
    @Input() blueLockFilters: boolean = true;

    headerText: string;

    constructor(
        private formService: FormService,
        private lookupService: LookupService,
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private router: Router,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService,
        private location: Location,
        private commentService: CommentService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'del-icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
        this.filterForm = this.formService.createSimpleForm(new ChangeManagementFilter());
    }

    ngOnInit() {
        this.changeManagementDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
                        this.resultsLength = data.totalCount;
        });

        this.changeManagementFilter$.pipe(take(1)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.getUsersPerProjectTeam(filter.projectTeamNames || []);
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
            this.pageSize = filter.take;
            this.showHideColumns = filter.showHideColumns;
        });
        this.projectTeamsService
            .getTeams()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((pt) => {
                this.projectTeamNames = pt;
            });
        this.isReadOnly = this.roleService.isReadOnly();

        this.activatedRoute.queryParamMap.pipe(takeWhile(() => this.isAlive)).subscribe((queryParamMap) => {
            if(this.blueScreen) {
                this.changeType = this.blueChangeType ? this.blueChangeType : null;
                this.subsystem = this.blueSubsystem ? this.blueSubsystem : null;
                this.rfo = this.blueRFO ? this.blueRFO : null;
                this.allowCommentsBulkUpdate = this.blueAllowComments;
                this.lockFilters = this.blueLockFilters;
            } else {
                this.subsystem = queryParamMap.get('subsystem');
                this.rfo = queryParamMap.get('rfo');
                this.changeType = queryParamMap.get('changeType');                
                this.lockFilters = queryParamMap.get('lockFilters') === 'true';
                this.allowCommentsBulkUpdate = queryParamMap.get('allowCommentsBulkUpdate') === 'true';
            }

            
            
            
            const discipline = queryParamMap.get('discipline');
            const subsystems = queryParamMap.getAll('subsystems').map((s) => ({ id: s }));
            const projectTeamNames = queryParamMap.getAll('projectTeamNames');
            const mcMilestones = queryParamMap.getAll('mcMilestones').map((m) => ({ name: m }));
            const goocs = queryParamMap.getAll('goocs').map((g) => ({ no: g }));
            this.rfos = queryParamMap.getAll('rfos').map((s) => ({ name: s } as RFO));
            this.contractorsFilter = queryParamMap.getAll('contractors').map(
                (s) =>
                    ({
                        contractNo: s,
                        name: s,
                    } as Contractor)
            );
            this.stagedStartUpPriorityFilter = queryParamMap.getAll('stagedStartUpPriority').map(
                (p) =>
                    ({
                        priority: p,
                        priorityName: p,
                    } as StagedStartUpPriority)
            );
            this.mcEngineersFilter = queryParamMap.getAll('mcEngineers').map((s) => +s);
            this.systemOwnersFilter = queryParamMap.getAll('systemOwners').map((s) => +s);
            const remaining = queryParamMap.get('remaining') === 'true';

            if (this.subsystem != null) {
                this.headerText = `Change by subsystem: ${this.subsystem}`;
            } else if (this.rfo != null) {
                this.headerText = `Change by RFO: ${this.rfo}`;
            }

            this.isNewPage = queryParamMap.get('new') === 'true';

            if (this.isNewPage) {
                this.store.dispatch(new ChangeManagementFilterReset());
                this.router.navigate(['/changemanagement']);
                this.store.dispatch(new ChangeManagementFilterRequest());
            }
            if (this.lockFilters) {
                this.getSystemOwnerAndEnginner(projectTeamNames);
                this.filterForm.controls.subsystems.setValue(
                    this.subsystem ? [{ id: this.subsystem }] : subsystems ? subsystems : []
                );
                let rfoFilter = new RFO();
                rfoFilter.name = this.rfo;

                this.filterForm.controls.rfos.setValue(this.rfo ? [rfoFilter] : []);
                if (this.rfos && this.rfos.length > 0) {
                    this.filterForm.controls.rfos.setValue(this.rfos);
                }
                this.filterForm.controls.mcEngineer.setValue(this.mcEngineersFilter ? this.mcEngineersFilter : []);
                this.filterForm.controls.systemOwner.setValue(this.systemOwnersFilter ? this.systemOwnersFilter : []);
                this.filterForm.controls.contractors.setValue(this.contractorsFilter ? this.contractorsFilter : []);
                this.filterForm.controls.changeTypes.setValue(this.changeType ? [this.changeType] : []);
                this.filterForm.controls.disciplines.setValue(discipline ? [discipline] : []);
                this.filterForm.controls.stagedStartUpPriority.setValue(this.stagedStartUpPriorityFilter ? this.stagedStartUpPriorityFilter : []);
                this.filterForm.controls.projectTeamNames.setValue(projectTeamNames);
                this.filterForm.controls.mcMilestones.setValue(mcMilestones);
                this.filterForm.controls.goocs.setValue(goocs);
                this.filterForm.controls.remaining.setValue(remaining);

                if (this.changeType === changeTypeEnumToString(ChangeType.NPWRedzone) && !remaining) {
                    this.filterForm.controls.designStatuses.setValue(this.npwStatuses);
                    this.filterForm.controls.changeTypes.setValue(this.npwRedzoneChangeTypes);
                } else if (this.changeType === changeTypeEnumToString(ChangeType.NPWBluezone) && !remaining) {
                    this.filterForm.controls.designStatuses.setValue(this.npwStatuses);
                    this.filterForm.controls.changeTypes.setValue(this.npwBluezoneChangeTypes);
                } else if (this.changeType === changeTypeEnumToString(ChangeType.NPW) && !remaining) {
                    this.filterForm.controls.designStatuses.setValue(this.npwStatuses);
                    this.filterForm.controls.changeTypes.setValue(this.npwChangeTypes);
                }
                this.updateFilterByFormProperties();
            }

            this.filterForm.controls.waypoints.valueChanges
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((waypoints: Waypoint[]) => {
                    this.prevWaypoints = waypoints;
                    this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                });

            this.filterForm.controls.milestones.valueChanges
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((milestones: Milestone[]) => {
                    let waypoints = JSON.parse(JSON.stringify(milestones));
                    waypoints.forEach((x) => (x.name = x.name.substring(0, x.name.lastIndexOf('.'))));
                    waypoints = _.unionBy(this.prevWaypoints, waypoints, 'name');
                    if (waypoints.length !== 0 || this.prevWaypoints.length !== 0) {
                        this.setWaypointInput.emit(new SetInputEventArgs(false, '', waypoints));
                        this.filterForm.controls.waypoints.setValue(waypoints);
                    }
                });

            if (this.changeType === changeTypeEnumToString(ChangeType.NPWRedzone) && !remaining) {
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'designStatuses',
                        value: this.npwStatuses,
                    })
                );
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'changeTypes',
                        value: this.npwRedzoneChangeTypes,
                    })
                );
            } else if (this.changeType === changeTypeEnumToString(ChangeType.NPWBluezone) && !remaining) {
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'designStatuses',
                        value: this.npwStatuses,
                    })
                );
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'changeTypes',
                        value: this.npwBluezoneChangeTypes,
                    })
                );
            } else if (this.changeType === changeTypeEnumToString(ChangeType.NPW) && !remaining) {
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'designStatuses',
                        value: this.npwStatuses,
                    })
                );
                this.store.dispatch(
                    new ChangeManagementFilterPropertyUpdate({
                        key: 'changeTypes',
                        value: this.npwChangeTypes,
                    })
                );
            }
            this.makeRequest$.pipe(takeWhile(() => this.isAlive)).subscribe((makeRequest) => {
                if (makeRequest || this.resultsLength === 0) {
                    this.store.dispatch(new ChangeManagementFilterRequest());
                }
            });
        });

        if (!this.lockFilters) {
            forkJoin([this.lookupService.getDesignStatuses(), this.lookupService.getChangeTypes(), this.lookupService.getFieldImplementationContractors()])
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(([designStatuses, changeTypes, fiContractors]) => {
                    this.designStatuses = designStatuses;
                    this.changeTypes = changeTypes;
                    this.fieldImplementationContractors = fiContractors;
                });
        }

        this.changeManagementLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines: any[]) => (this.disciplines = disciplines),
                () => {
                    this.toastService.Error(
                        'Error occurred while getting disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new ChangeManagementFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new ChangeManagementFilterRequest());
        });

        this.sortBy$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.sortBy = data));
        this.direction$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.direction = data));

        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnComments = data));
        this.columnContractor$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContractor = data));
        this.columnFieldImplementationContractor$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnFieldImplementationContractor = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnDesignStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnDesignStatus = data));
        this.columnPriority$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnPriority = data));
        this.columnRFIOriginator$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnRFIOriginator = data));
        this.columnImplementationStatus$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnImplementationStatus = data));
       this.columnNBResponsiblePersonComments$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnNBResponsiblePersonComments = data));           
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevision = data));
        this.columnAreaBreakDown$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAreaBreakDown = data));
        this.columnExceptionNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnExceptionNumber = data));
        this.columnValidationStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnValidationStatus = data));
        this.columnSystemOwner$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSystemOwner = data));
        this.columnRFOForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFOForecast = data));
        this.columnExpectedTimeOfCompletion$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnExpectedTimeOfCompletion = data));
        this.columnId$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnId = data));
        this.columnSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubsystem = data));
        this.columnSignedSubsystem$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSignedSubsystem = data));
        this.columnLinkedIWPs$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnLinkedIWPs = data));
        this.columnLinkedCOFs$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnLinkedCOFs = data));
        this.columnRFSUContractor$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUContractor = data));
        this.columnRFSUContractsPending$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUContractsPending = data));
        this.columnTitle$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnTitle = data));
        this.columnRFO$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFO = data));
        this.columnRFOStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFOStatus = data));
        this.columnChangeType$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnChangeType = data));
        this.columnDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDate = data));
        this.columnRFSUForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUForecast = data));
        this.columnWalkdownForecast$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnWalkdownForecast = data));
        this.columnCreatedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnCreatedDate = data));
        this.columnRFSUPlan$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRFSUPlan = data));
        this.columnMcActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnMcActual = data));
        this.columnWalkdownActual$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnWalkdownActual = data));
        this.columnStepStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStepStatus = data));  
        this.isNBResponsiblePerson = this.roleService.isInRole(Constants.applicableGroups.ResponsiblePerson);
    }

    reverseValue(columnName: string) {
        this.showHideColumns = {
            ...this.showHideColumns,
            [columnName]: !this.showHideColumns[columnName],
        };
        this.store.dispatch(
            new ChangeManagementFilterPropertyUpdate({
                key: 'showHideColumns',
                value: this.showHideColumns,
            })
        );
        this.filterForm.controls.showHideColumns.setValue(this.showHideColumns);
    }

    displaySelectedMCEngineer(mcEngineerIds: number[]) {
        return this.mcEngineers
            .filter((mcEngineer) => mcEngineerIds.includes(mcEngineer.id))
            .map((mcEngineer) => mcEngineer.name)
            .join(', ');
    }

    displayMultipleSelectedById(values: any[]) {
        return values.map((x) => x.id).join(', ');
    }

    getSystemOwnerAndEnginner(projectTeamNames: string[]) {
        forkJoin([
            this.lookupService.getMCEngineers(projectTeamNames),
            this.lookupService.getSysOwners(projectTeamNames),
        ])
            .pipe(
                take(1),
                catchError(() => {
                    throw 'Error occurred while getting users per project team. Please contact Program Administrator.';
                })
            )
            .subscribe(
                ([mcEngineers, sysOwners]) => {
                    this.mcEngineers = mcEngineers;
                    this.sysOwners = sysOwners;
                },
                (error) => {
                    this.toastService.Error(error);
                }
            );
    }

    displaySelectedSystemOwner(systemOwnerIds: number[]) {
        return this.sysOwners
            .filter((systemOwner) => systemOwnerIds.includes(systemOwner.id))
            .map((systemOwner) => systemOwner.name)
            .join(', ');
    }

    navigateToFieldChangeSummary() {
        const subsystem = encodeURIComponent(this.filterForm.controls.subsystems.value[0].id);
        this.router.navigate([`/changemanagement/fieldchangesummary/${subsystem}`]);
    }

    getContractorsMulti = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

	getStagedStartUpPriorityMulti = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((stagedStartUpPriority: StagedStartUpPriority[]) => stagedStartUpPriority));
    };

    getWaypoints = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchWaypoints(search, take, page, this.filterForm.value.projectTeamNames).pipe(
            map((waypoints: Waypoint[]) => {
                return waypoints;
            })
        );
    };

    getMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMilestones(
                search,
                take,
                page,
                this.filterForm.value.waypoints,
                this.filterForm.value.projectTeamNames
            )
            .pipe(map((milestones: Milestone[]) => milestones));
    };

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.filterForm.value.projectTeamNames);
    };

    getTurnoverGroups = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchTurnoverGroups(search, take, page)
            .pipe(map((turnoverGroups) => turnoverGroups));
    };

    private getLatestFilterData(): ChangeManagementFilter {
        let filter: ChangeManagementFilter;
        this.store.pipe(select((x) => x.changeManagementState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getAreaBreakDown = (search = '', take = 30, page = 0) =>
        this.lookupService.searchAreaBreakDownWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getExceptionNumber = (search = '', take = 30, page = 0) =>
        this.lookupService.searchExceptionNumberWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getValidationStatus = (search = '', take = 30, page = 0) =>
        this.lookupService.searchValidationStatusWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getDisciplines = (search = '', take = 30, page = 0) =>
        this.lookupService.searchDisciplinesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getContractors = (search = '', take = 30, page = 0) =>
        this.lookupService.searchContractorsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    
    getFieldImplementationContractors = (search = '', take = 30, page = 0) =>
        this.lookupService.searchFieldImplementationContractorsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getStagedStartUpPriority = (search = '', take = 10, page = 0) =>
        this.lookupService
            .searchStagedStartUpPriorities(search, take, page, this.filterForm.value.projectTeamNames);            
    getTypes = (search = '', take = 30, page = 0) =>
        this.lookupService.searchChangeTypesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getIds = (search = '', take = 30, page = 0) =>
        this.lookupService.searchIdsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRFO = (search = '', take = 30, page = 0) =>
    this.lookupService.searchRFOsWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getRFOStatus = (search = '', take = 30, page = 0) =>
    this.lookupService.searchRFOStatusWithRFOZoneFilter(search, take, page, this.getLatestFilterData());

    getSubsystemsForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService
            .searchSubsystemsWithChangeManagementFilter(search, take, page, this.getLatestFilterData())
            .pipe(
                map((subsys) => {
                    if ((search == '' || search == 'N/A') && page === 0) {
                        subsys.unshift('N/A');
                    }
                    return subsys;
                })
            );
    
    getSignedSubsystemsForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService
            .searchSignedSubsystemsWithChangeManagementFilter(search, take, page, this.getLatestFilterData())
            .pipe(
                map((subsys) => {
                        if ((search == '' || search == 'N/A') && page === 0) {
                            subsys.unshift('N/A');
                        }
                        return subsys;
                    }
                )
        );

    getLinkedIWPsForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchLinkedIWPsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getLinkedCOFsForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchLinkedCOFsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRFSUContractorForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFSUContractorWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getRFSUContractsPendingForColumn = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFSUContractsPendingWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getTitles = (search = '', take = 30, page = 0) =>
        this.lookupService.searchTitlesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getPriorities = (search = '', take = 30, page = 0) =>
        this.lookupService.searchPrioritiesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    
    getRFIOriginators = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRFIOriginatorsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());
    
    getRevisions = (search = '', take = 30, page = 0) =>
        this.lookupService.searchRevisionsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getSystemOwners = (search = '', take = 30, page = 0) =>
        this.lookupService.searchSystemOwnersWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchCommentsWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStatusesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getStepStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchStepStatusesWithChangeManagementFilter(search, take, page, this.getLatestFilterData());

    getImplementationStatuses = (search = '', take = 30, page = 0) =>
        this.lookupService.searchImplementationStatusesWithChangeManagementFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );
        getNBResponsiplePersonComments = (search = '', take = 30, page = 0) =>
        this.lookupService.searchResponsiblePersonWithChangeManagementFilter(
            search,
            take,
            page,
            this.getLatestFilterData()
        );
    onProjectTeamsClosed(isOpen: boolean) {
        if (!isOpen && this.projectTeamNamesCurrent !== this.filterForm.controls.projectTeamNames.value) {
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        this.projectTeamNamesCurrent = this.filterForm.controls.projectTeamNames.value;
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    onWaypointsClosed() {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new ChangeManagementFilterReset());
        this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        this.setContractorInput.emit(new SetInputEventArgs(false, ''));
        this.setTurnoverInput.emit(new SetInputEventArgs(false, ''));
        this.setSubsystemInput.emit(new SetInputEventArgs(false, ''));
        this.setGoocInput.emit(new SetInputEventArgs(false, ''));
        this.setMcMilestoneInput.emit(new SetInputEventArgs(false, ''));
        this.setRFOInput.emit(new SetInputEventArgs(false, ''));
		this.setStagedStartUpPriorityInput.emit(new SetInputEventArgs(false, ''));
        this.filterForm.setValue(new ChangeManagementFilter());
        this.setAreaBreakdownInput.emit(new SetInputEventArgs(false, ''));
        this.setValidationStatusInput.emit(new SetInputEventArgs(false, ''));
        this.setRFSUContractorsInput.emit(new SetInputEventArgs(false, ''));
	}
    displaySelectedPriorities(priorities: StagedStartUpPriority[]) {
        return priorities.map((p) => p.priorityName).join(', ');
    }

    displayGoocsSelected(values: Gooc[]) {
        return values.map((x) => x.no).join(', ');
    }

    getAreaBreakdown = (search = '', take = 30, page = 0) =>
    this.lookupService.searchAreaBreakdown(search, take, page, this.filterForm.value.projectTeamNames);

    getRFSUContractors = (search = '', take = 10, page = 0) => {
            return this.lookupService.searchRFSUContractors(search, take, page, this.filterForm.value.projectTeamNames).pipe(
                map((rfsuContractors: Contractor[]) => {
                    return rfsuContractors;
                })
            );
        };

    getMcMilestones = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchMcMilestones(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((mcMilestones: McMilestone[]) => mcMilestones));
    };

    getGoocs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchGoocs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((goocs: Gooc[]) => goocs));
    };

    getRFOs = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchRFOs(search, take, page, this.filterForm.value.projectTeamNames)
            .pipe(map((rfos: RFO[]) => rfos));
    };

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new ChangeManagementFilterPropertyUpdate({ key, value }));
                if (key.indexOf('column') === -1 && value !== null && value.length > 0) {
                    this.setHeaderFilterPerPageFilter(this.filterForm, key, value);
                }
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new ChangeManagementFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new ChangeManagementFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ChangeManagementFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ChangeManagementFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ChangeManagementFilterRequest());
    }

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }

        if (propertyName == 'projectTeamNames') {
            this.getUsersPerProjectTeam(this.filterForm.controls.projectTeamNames.value);
        }
        if (propertyName == 'waypoints') {
            this.setMilestoneInput.emit(new SetInputEventArgs(false, ''));
            this.setWaypointInput.emit(new SetInputEventArgs(false, ''));
        }
    }

    openCommentPopup(element: ChangeManagementDTO, event: any) {
        const comment = element.comment || '';
        if (this.isReadOnly) return;

        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                mentions: element.mentions,
                url: `changeDocumentDetails/${element.type}/${element.id}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new ChangeManagementAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new ChangeManagementAddCommentRequest({
                                id: element.id,
                                description: value,
                                mentions,
                                // zone: Zone.ChangeManagement
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }

    openNBResponsiblePersonCommentPopup(element: ChangeManagementDTO, event: any) {
        const comment = element.latestNBResponsiblePersonComment || '';      
        if (!this.isNBResponsiblePerson) return;    
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                filteredCount: this.allowCommentsBulkUpdate ? this.resultsLength : 0,
                mentions: element.mentions,
                url: `changeDocumentDetails/${element.type}/${element.id}`,
                action: (value: string, mentions: UserDetail[], bulk: boolean) => {
                    if (bulk) {
                        this.store.dispatch(
                            new ChangeManagementAddBulkCommentRequest({
                                description: value,
                                mentions,
                            })
                        );
                    } else {
                        this.store.dispatch(
                            new ChangeManagementAddCommentRequest({
                                id: element.id,
                                description: value,                           
                                mentions,
                                zone: Zone.ResponsiblePerson
                            })
                        );
                    }
                },
            })
        );

        event.stopPropagation();
    }


    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        showBlankOptions: boolean = false
    ) {
        const excludeBlanks = selected === null ? false : selected.excludeBlanks;
        const onlyBlanks = selected === null ? false : selected.onlyBlanks;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderCheckListFilter>(
                HeaderChecklistFilterComponent,
                columnName === 'title' ? 800 : 400,
                650,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                    placeHolder: placeholder,
                    searchFunc: searchFunc,
                    selectedItems: selected ? [...selected.items] : [],
                    excludeBlanks: excludeBlanks,
                    onlyBlanks: onlyBlanks,
                    isSortingOff: columnName === 'subsystem'|| columnName === 'signedSubsystem' || columnName === 'rfo' || columnName === 'rfoStatus' || columnName === 'linkedIWPs' || columnName === 'areaBreakDown' || columnName === 'exceptionNumber' ? true : false,
                    showCounts: showCounts,
                    showInputSearch: showInputSearch,
                    showBlankOptions: showBlankOptions,
                    action: (data) => {
                        if(columnName === 'currentStep' && (data.selectedItems.indexOf('N/A') > -1 || data.selectedItems.indexOf('') > -1))
                        {
                            data.selectedItems = data.selectedItems.map(item => {
                                item === 'N/A' ? item = null : item === '' ? item = '0' : item=item;
                                return item;
                            });
                        }             
                        let value = new CheckListColumn();
                        value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                        value.excludeBlanks = data.excludeBlanks;
                        value.onlyBlanks = data.onlyBlanks;
                        this.filterForm.controls[propertyName].setValue(value);

                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                        this.search();
                    },
                    resetColumnAction: () => {
                        this.store.dispatch(
                            new ChangeManagementFilterPropertyUpdate({
                                key: propertyName,
                                value: null,
                            })
                        );
                    },
                    cancelAction: () => {
                        this.store.dispatch(
                            new ChangeManagementFilterPropertyUpdate({
                                key: propertyName,
                                value: this.filterForm.controls[propertyName].value,
                            })
                        );
                    },
                }
            )
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new ChangeManagementFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new ChangeManagementFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'id', direction: OrderDirection.Desc },
                })
            );
        }
    }

    setCorrectValuesPerProjectTeam(projectTeamNames: string[], controlName: string) {
        if (projectTeamNames.length === 0) {
            return;
        }
        let valid =
            this.filterForm.controls[controlName].value.length &&
            this.filterForm.controls[controlName].value.filter(
                (item) => projectTeamNames.indexOf(item.projectTeamName) !== -1
            );
        if (valid) {
            this.filterForm.controls[controlName].setValue(valid);
        }
    }

    openHistoryPopup(element: ChangeManagementDTO) {
        if (element.comment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService.getComments(element.id.toString(), SubsystemScope.ChangeDocument).subscribe((data) => {
                this.isLoading = false;
                this.popupSvc.openPopup(
                    new PopupSettings(
                        CommentsHistoryComponent,
                        null,
                        null,
                        {
                            commentHist: data,
                            action: (id: number) => {
                                data = data.filter((s) => s.id !== id);
                                this.store.dispatch(new ChangeManagementDeleteCommentRequest({ id: id }));
                            },
                        },
                        200,
                        200
                    )
                );
            });
        }
    }

    private getUsersPerProjectTeam(projectTeamNames: string[]) {
        this.setMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'milestones');
        this.setWaypointInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'waypoints');
        this.setContractorInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'contractors');
        this.setGoocInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'goocs');
        this.setMcMilestoneInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'mcMilestones');
        this.setRFOInput.emit(new SetInputEventArgs(true));
        this.setCorrectValuesPerProjectTeam(projectTeamNames, 'rfos');
    }

    openDetailsPage(row: any) {
        this.ngZone.run(() => this.router.navigate([`/changeDocumentDetails/${row.type}/${row.id}`]));
    }

    exportToExcel() {
        this.store.dispatch(new ChangeManagementExportToExcelRequest());
    }
    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupSvc.openPopup(
            new PopupSettings<HeaderDateFilter>(
                HeaderDateFilterComponent,
                null,
                null,
                {
                    isAscendingSort: this.sortBy === columnName && this.direction == OrderDirection.Asc,
                    isDescendingSort: this.sortBy === columnName && this.direction == OrderDirection.Desc,
                    calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                    placeHolder: placeholder,
                    isSortingOff: false,
                    action: (data) => {
                        this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                        this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                        this.search();
                    },
                },
                345,
                580
            )
        );
    }

    updateFieldImplementationContractor(change: MatSelectChange, changeDocId: number) {
        this.store.dispatch(
            new ChangeManagementUpdateFieldImplementationContractorRequest({
                id : changeDocId,
                fieldImplementationContractor : change.value                
            })
        );
    }

    fieldImplementationContraHistory(event: MouseEvent,changeDocId: number) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(FieldImplementationContractorHistoryComponent, 550, 500, {
                changeDocId: changeDocId
            })
        );
    }

    fieldImplementationAccess(){
        let isValid = this.roleService.isInRole(Constants.applicableGroups.Admin) ||
        this.roleService.isInRole(Constants.applicableGroups.ContractorFieldAccess)
        ? true : false;

        return isValid;
    }

    resetColumns() {
        this.showHideColumns = new ShowHideColumns();
    }

    beatifySighnedSS(el: ChangeManagementDTO) {
        let subs = el.subsystem
                .split(', ')
                .map(s => {
                    if (el.signedSubsystem.includes(s))
                        return s
                    else 
                        return ' '
                })
                .join(', ')
        subs = subs + ', ' // adding in case of trimming
        return subs
    }

    openETCDatepicker(event: any ,element: ChangeManagementDTO, type: string) {
        event.stopPropagation();
        let date;
        //if (this.isAdmin || this.isCriticalRFIExpectedTimeOfCompletionUpdateAllowed) {
        if (true) {

            this.popupSvc.openPopup(
                new PopupSettings(ClearableMatDatepickerComponent, 550, 700, {
                  selectedDate: date,
                  dateType: 'common',               
                  commonSaveAction: (d: moment.Moment) => {

                    this.saveETCDate(element, type,d);
                  },
                })
              );
        } else{
            return;
        }
    }

    saveETCDate(row: ChangeManagementDTO, type: string, expectedClosureDate: moment.Moment) {
        if (type== "expectedTimeOfCompletion"){
             let selectedExpectedClosureDate =  expectedClosureDate ;
                 const payload = {
                    rowId: row.id,
                    expectedClosureDate: selectedExpectedClosureDate
                 } ;
             this.store.dispatch(new ChangeManagementAddExpectedClosureDateRequest(payload));
        }
     }

    expectedTimeofCompletionHistory(event: MouseEvent,changeDocId: number) {
        event.preventDefault();
        this.popupSvc.openPopup(
            new PopupSettings(EtcHistoryComponent, 550, 500, {
                changeDocId: changeDocId,
                changeType: "Change Document"
            })
        );
    }
}

import { Observable } from 'rxjs';
import { CalendarColumn } from '../store/common.model';

interface HeaderDate {
    isAscendingSort: boolean;
    isDescendingSort: boolean;
    calendarColumn: CalendarColumn;
}

export interface HeaderDateFilter extends HeaderDate {
    placeHolder: string;
    isSortingOff: boolean;
    action(data: HeaderDateFilter): void;
}

export interface HeaderDateFilterResult extends HeaderDate {}

interface HeaderCheckList {
    isAscendingSort: boolean;
    isDescendingSort: boolean;
    selectedItems: string[];
    selectedFlags?: string[];
    excludeBlanks?: boolean;
    onlyBlanks?: boolean;
}

export interface HeaderCheckListFilter extends HeaderCheckList {
    placeHolder: string;
    isSortingOff: boolean;
    showCounts: boolean;
    showInputSearch: boolean;
    showBlankOptions?: boolean;
    showTrafficLightFilter?: boolean;
    selectedFlags?: string[];
    searchFunc: (id?: string, take?: number, page?: number, column?: string, allElements?: any[]) => Observable<any[]>;
    action(data: HeaderCheckListFilterResult): void;
    resetColumnAction(): void;
    cancelAction?(): void;
    column?: string;
    allElements?: any[];
}

export interface HeaderCheckListFilterResult extends HeaderCheckList {}

interface HeaderNumeric {
    isAscendingSort: boolean;
    isDescendingSort: boolean;
    minValue?: number;
    maxValue?: number;
    excludeBlanks: boolean;
    onlyBlanks: boolean;
}

export interface HeaderNumericFilter extends HeaderNumeric {
    placeHolder?: string;
    showBlankOptions?: boolean;
    onSumbit?(data: HeaderNumeric): void;
    onReset?(): void;
    onCancel?(): void;
}
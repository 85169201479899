import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { OrderDirection } from 'src/app/store/common.model';
import {
    ChangeImpactedTagsFilterPropertyUpdate,
    ChangeImpactedTagsFilterRequest,
    ChangeImpactedTagsFilterReset,
    ChangeImpactedTagsNewDataSetRequest,
    ChangeImpactedTagsUploadRequest,
} from 'src/app/store/change-management-upload-dcn/actions';
import { LookupService } from 'src/app/services/api/webapi-services/lookup.service';
import { takeWhile} from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { ToastService } from 'src/app/services/shared/toast.service';
import {
    DcnDTO,
    ChangeImpactedTagsFilter,
    ImpactedTagsPopupInput,
} from 'src/app/store/change-management-upload-dcn/model';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ImpactedTagsPopupComponent } from '../impacted-tags-popup/impacted-tags-popup.component';
import { PopupSettings } from 'src/app/models/popup-settings';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'app-upload-dcn-impacted-tags',
    templateUrl: './upload-dcn-impacted-tags.component.html',
    styleUrls: ['./upload-dcn-impacted-tags.component.scss'],
})
export class UploadDcnImpactedTagsComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    sortBy: string;
    direction = OrderDirection.Desc;
    data: DcnDTO[] = [];
    resultsLength = 0;
    pageSize = 10;
    displayedColumns = ['number', 'title', 'impactedTags', 'edit'];
    isAdmin = false;

    changeImpactedTagsFilter$ = this.store.select((store) => store.changeImpactedTagsState.filter);
    changeImpactedTagsLoader$ = this.store.select((store) => store.changeImpactedTagsState.isLoading);
    changeImpactedTagsDataPagination$ = this.store.select((store) => store.changeImpactedTagsState.dataPagination);

    @ViewChild(MatExpansionPanel, { static: true }) filterExpansionPanel: MatExpansionPanel;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private lookupService: LookupService,
        private toastService: ToastService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private popupSvc: PopupService,
        private roleSvc: RoleService
    ) {
        super();

        this.filterForm = this.formService.createSimpleForm(new ChangeImpactedTagsFilter());
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.filterExpansionPanel.expanded = true;
        this.isAdmin = this.roleSvc.isInRole(Constants.applicableGroups.Admin);

        this.changeImpactedTagsDataPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.changeImpactedTagsFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.paginator.pageIndex = filter.page;
        });

        this.changeImpactedTagsLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new ChangeImpactedTagsFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new ChangeImpactedTagsFilterRequest());
        });

        this.store.dispatch(new ChangeImpactedTagsFilterRequest());
    }

    openPopup(element: DcnDTO) {
        this.popupSvc.openPopup(
            new PopupSettings<ImpactedTagsPopupInput>(ImpactedTagsPopupComponent, 500, 400, {
                element,
                action: (impactedTags: string[]) => {
                    this.store.dispatch(
                        new ChangeImpactedTagsNewDataSetRequest({ id: element.id, impactedTags: impactedTags })
                    );
                },
            })
        );
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        let pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    resetFilters() {
        this.filterExpansionPanel.expanded = true;
        this.store.dispatch(new ChangeImpactedTagsFilterReset());
    }

    private updateFilterByFormProperties() {
        for (const key of Object.keys(this.filterForm.controls)) {
            if (!(key === 'sortBy' || key === 'direction')) {
                const value = this.filterForm.controls[key].value;
                this.store.dispatch(new ChangeImpactedTagsFilterPropertyUpdate({ key, value }));
            }
        }
    }

    search() {
        this.updateFilterByFormProperties();

        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new ChangeImpactedTagsFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new ChangeImpactedTagsFilterRequest());
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new ChangeImpactedTagsFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new ChangeImpactedTagsFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new ChangeImpactedTagsFilterRequest());
    }

    clearFilterProperty(propertyName: string) {
        if (Array.isArray(this.filterForm.controls[propertyName].value)) {
            this.filterForm.controls[propertyName].setValue([]);
        } else {
            this.filterForm.controls[propertyName].setValue('');
        }
    }

    fileChangeEvent(files: Array<File>, id: number) {
        const formData = new FormData();

        formData.append(files[0].type, files[0], files[0].name);

        this.store.dispatch(new ChangeImpactedTagsUploadRequest({ formData, id }));
    }

    parseWithCommas(impactedTags: string[]) {
        return impactedTags !== null && impactedTags.length > 0 ? impactedTags.join(', ') : '';
    }
}

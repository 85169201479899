<div>
    <form>
        <span class="close" (click)="close(false)">&times;</span>
        <p class="title">
            Enter impacted tags for
            <br />
            <b>{{ element.number }}</b>
        </p>
        (Entries should be comma separated)

        <textarea
            class="text-area"
            [(ngModel)]="impactedTagsText"
            [ngModelOptions]="{ standalone: true }"
            placeholder="tags"
        >
        </textarea>

        <div class="buttons-container flex center">
            <button type="button" (click)="close(false)" class="sct-button sct-button-white">cancel</button>
            <button type="button" (click)="close(true)" class="sct-button sct-button-light ok-button">ok</button>
        </div>
    </form>
</div>

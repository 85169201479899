<div class="soc-plan-container" *ngIf="socPlan$ | async">
    <h3>System Owner Coordination Plan</h3>
    <ngx-charts-pie-chart
        [view]="[400, 400]"
        [results]="chartData"
        [legend]="false"
        [labels]="true"
        [tooltipDisabled]="true"
        [scheme]="colorScheme"
    >
    </ngx-charts-pie-chart>
    <button type="button" class="sct-button sct-button-light" (click)="save()">
        save SOC plan
    </button>
    <form [formGroup]="socPlanForm">
        <mat-form-field>
            <mat-label>Complete</mat-label>
            <input matInput type="number" min="0" formControlName="complete" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>In Progress</mat-label>
            <input matInput type="number" min="0" formControlName="inProgress" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Not Started</mat-label>
            <input matInput type="number" min="0" formControlName="notStarted" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Hold</mat-label>
            <input matInput type="number" min="0" formControlName="hold" />
        </mat-form-field>
    </form>
    <div *ngIf="isLoading$ | async" class="upload-log-loader">
        <div class="lds-hourglass"></div>
    </div>
</div>

<mat-form-field class="multiselect-form-field" [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        type="text"
        [placeholder]="placeholder"
        [matAutocomplete]="auto"
        [formControl]="inputControl"
        [required]="required"
    />
    <button *ngIf="inputControl.value.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="onInputClear()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="errorMessage">
        {{ errorMessage }}
    </mat-error>
    <mat-autocomplete
        #auto="matAutocomplete"
        (opened)="onAutocompleteOpened()"
        (closed)="onAutocompleteClosed()"
        [panelWidth]="panelWidth"
    >
        <ng-container *ngIf="filteredOptions$ | async as filteredOptions">
            <ng-container *ngIf="!loading">
                <cdk-virtual-scroll-viewport [itemSize]="optionHeight" [ngStyle]="{ 'height.px': dropdownHeight }">
                    <mat-option *cdkVirtualFor="let option of filteredOptions" class="multiselect-option">
                        <div (click)="onOptionClicked($event, option)">
                            <mat-checkbox
                                *ngIf="option.label == selectAllText; else normalOption"
                                [checked]="isAllComplete(filteredOptions)"
                                [indeterminate]="isSomeComplete(filteredOptions)"
                                (change)="setAllComplete(filteredOptions, $event.checked)"
                                (click)="$event.stopPropagation()"
                            >
                                {{ option.label }}
                            </mat-checkbox>
                            <ng-template #normalOption>
                                <mat-checkbox
                                    *ngIf="!single"
                                    [checked]="isOptionSelected(option)"
                                    (change)="toggleSelection(option)"
                                    (click)="$event.stopPropagation()"
                                >
                                    {{ option.label }}
                                </mat-checkbox>
                            </ng-template>
                            <span *ngIf="single">{{ option.label }}</span>
                        </div>
                    </mat-option>
                    <mat-option *ngIf="filteredOptions.length === 0" class="multiselect-option">
                        No results found
                    </mat-option>
                </cdk-virtual-scroll-viewport>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="loading">
            <mat-option [disabled]="true">
                <div class="lds-hourglass"></div>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>

<main class="main">
    <a class="navigator" [routerLink]="['/reports']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">GOOC Pre-MC Dashboard</div>
    </div>
    <form [formGroup]="filterForm" class="filter-container">
        <app-multiple-items-selector
            class="container620"
            [setInput]="setGoocInput"
            formControlName="goocs"
            [searchFunc]="getGoocs"
            [isAsync]="true"
            [isTableAutoComplete]="true"
            [displayedColumns]="goocAutocompleteDisplayedColumns"
            [filterForm]="filterForm"
            [formSetter]="'goocs'"
            [propertyToShow]="'no'"
            [placeholder]="'GOOC'"
            [oneItemMode]="true"
        >
        </app-multiple-items-selector>
    </form>
    <div class="buttons-container flex">
        <button
            type="button"
            (click)="search()"
            class="sct-button sct-button-light"
            [disabled]="!filterForm.controls.goocs.value.length"
        >
            search
        </button>
    </div>
    <app-pre-mc-schedule></app-pre-mc-schedule>
    <app-pre-mc-skyline></app-pre-mc-skyline>
    <div class="earlyw-container">
        <app-early-works></app-early-works>
        <app-early-walkdowns></app-early-walkdowns>
        <app-soc-plan></app-soc-plan>
    </div>
    <app-certification-status></app-certification-status>
    <app-loading-indicator *ngIf="isLoading$ | async"></app-loading-indicator>
</main>

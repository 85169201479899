import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { FormService } from 'src/app/services/shared/form.service';
import { ApplicationState } from 'src/app/store/model';
import { SOCPlanSaveRequest } from 'src/app/store/reports/pre-mc/actions';
import { SOCPlanDTO } from 'src/app/store/reports/pre-mc/model';

@Component({
    selector: 'app-soc-plan',
    templateUrl: './soc-plan.component.html',
    styleUrls: ['./soc-plan.component.scss'],
})
export class SOCPlanComponent implements OnInit {
    socPlan$ = this.store.select((state) => state.preMCState.socPlan.entity);
    isLoading$ = this.store.select((state) => state.preMCState.socPlan.isLoading);

    chartData = [];
    socPlanForm: UntypedFormGroup;
    colorScheme = {
        domain: ['#5AA454', '#C7B42C', '#A10A28', '#915091'],
    };

    constructor(private store: Store<ApplicationState>, private formService: FormService) {
        this.socPlanForm = this.formService.createSimpleForm(new SOCPlanDTO());
    }

    ngOnInit() {
        this.socPlan$.pipe(filter((socPlan) => !!socPlan)).subscribe((socPlan) => this.socPlanForm.patchValue(socPlan));

        this.socPlanForm.valueChanges.subscribe((formValue: SOCPlanDTO) => {
            this.chartData = [
                { name: 'Complete', value: formValue.complete },
                { name: 'In Progress', value: formValue.inProgress },
                { name: 'Not Started', value: formValue.notStarted },
                { name: 'Hold', value: formValue.hold },
            ];
        });
    }

    save() {
        this.store.dispatch(new SOCPlanSaveRequest(this.socPlanForm.value));
    }
}

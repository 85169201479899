import { Injectable } from '@angular/core';
import { ChangeManagementFilter, ChangeManagementDataPagination } from 'src/app/store/change-management/model';
import { ChangeDocumentDetailsDTO, IWPLink, CallOffAttachment } from 'src/app/store/change-document-details/model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { FieldChangeDocumentSummaryDTO } from 'src/app/store/field-change-summary/model';
import { SignOffRequest } from 'src/app/models/sing-off-request';
import { JsonPatchOperation } from 'src/app/store/common.model';
import { appConfig } from 'src/app/app.config';
import { FieldImplementationContractor } from 'src/app/store/field-implementation-contractor-history/model';
import { CriticalRfiDataPagination, CriticalRfiFilter } from 'src/app/store/critical-rfi/model';
import { CriticalRfiDTO } from 'src/app/store/critical-rfi/model';
import { ManualPriority } from 'src/app/store/manual-priority-history/model';
import { ExpectedTimeofCompletionDTO } from '../../../store/manual-priority-history/model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ChangeManagementService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly changeManagementEndpoint: string = '/changemanagement';

    constructor(private http: HttpClient) {}

    downloadCallOffAttachmentRequest(payload: {attachment: CallOffAttachment, type: string}) {
        const attachmentLink = encodeURIComponent(payload.attachment.link)
        return this.http.get<Blob>(
            `${this.rootUrl}${this.changeManagementEndpoint}/attachments?link=${attachmentLink}&type=${payload.type}`,
            { responseType: 'arraybuffer' as 'json' }
        );
    }

    updateChangeDocSubPriority(doc: CriticalRfiDTO) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/updateChangeDocPriority`, doc);
    }

    updateChangeDocManualPriority(doc: CriticalRfiDTO) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/updateChangeDocManualPriority`, doc);
    }

    getChangeManagementData(filter: ChangeManagementFilter) {
        return this.http.post<ChangeManagementDataPagination>(
            `${this.rootUrl}${this.changeManagementEndpoint}`,
            filter
        );
    }

    getChangeDocumentDetailsData(id: string) {
        return this.http.get<ChangeDocumentDetailsDTO>(
            `${this.rootUrl}${this.changeManagementEndpoint}/changeDocument/${id}`
        );
    }

    getChangeManagementSummaryForSubsystem(subsystem: string) {
        const subsystemNumber = encodeURIComponent(subsystem);
        return this.http.get<FieldChangeDocumentSummaryDTO>(
            `${this.rootUrl}${this.changeManagementEndpoint}/fieldchangesummary?subsystem=${subsystemNumber}`
        );
    }

    getIWP(changeDocId: number) {
        return this.http.get(`${this.rootUrl}${this.changeManagementEndpoint}/iwps/${changeDocId}`);
    }

    updateIWPs(iwpLink: IWPLink) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/iwps`, iwpLink);
    }

    updateFieldImplementationContractor(payload : {id:number, fieldImplementationContractor:string}) {
        let params = new HttpParams();
        params = params.append('changeDocId',payload.id);
        params = params.append('contractor',payload.fieldImplementationContractor);
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/update1fieldimplementationcontractor`, {}, {params});
    }

    getFieldImplementationContractorHistory(
        changeDocId : number
    ) {
        return this.http.get<FieldImplementationContractor[]>(
            `${this.rootUrl}${this.changeManagementEndpoint}/getfieldimplementationcontractorhistory?changeDocId=${changeDocId}`,
            {}
        );
    }

    getManualPriorityHistory(
        changeDocId : number
    ) {
        return this.http.get<ManualPriority[]>(
            `${this.rootUrl}${this.changeManagementEndpoint}/getmanualpriorityhistory?changeDocId=${changeDocId}`,
            {}
        );
    }

    patchIWP(changeDocId: number, iwp: string, operations: JsonPatchOperation[]) {
        return this.http.patch(`${this.rootUrl}${this.changeManagementEndpoint}/iwp/${changeDocId}/${iwp}`, operations);
    }

    signOff(form: SignOffRequest) {
        return this.http.post<ChangeDocumentDetailsDTO>(
            `${this.rootUrl}${this.changeManagementEndpoint}/signOff`,
            form
        );
    }

    deleteSignOff(form: SignOffRequest) {
        return this.http.post<ChangeDocumentDetailsDTO>(
            `${this.rootUrl}${this.changeManagementEndpoint}/signOff/delete`,
            form
        );
    }

    generateExcel(filter: ChangeManagementFilter) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    generateCriticalRfiExcel(filter: CriticalRfiFilter) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/generatecriticalrfiexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    getCriticalRfiData(filter: CriticalRfiFilter) {
        return this.http.post<CriticalRfiDataPagination>(
            `${this.rootUrl}${this.changeManagementEndpoint}/criticalrfi`,
            filter
        );
    }

    addCriticalRfiExpectedClosureDate(criticalRfiData: CriticalRfiDTO) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/addcriticalRfiExpectedTimeOfCompletion`, criticalRfiData);
    }

    addChangeDocETCDate(changeDocId: number, ETCDate: moment.Moment,) {
        return this.http.post(`${this.rootUrl}${this.changeManagementEndpoint}/addChangeDocETC/${changeDocId}/${ETCDate.toJSON()}`, null);
    }

    getExpectedTimeofCompletionHistory(
        changeDocId : number
    ) {
        return this.http.get<ExpectedTimeofCompletionDTO[]>(
            `${this.rootUrl}${this.changeManagementEndpoint}/getExpectedTimeofCompletionHistory?changeDocId=${changeDocId}`,
            {}
        );
    }
}
